/**
 * Expired session warning page
 */
import React from "react";
import {Typography, withStyles, Box, Paper, Grid} from "@material-ui/core";
import {Alert, AlertTitle} from "@material-ui/lab";
import {useTranslation, Trans} from "react-i18next";
import Icon from "../../icons/LockClockTwoTone";

const ExpiredSession = withStyles(
  (theme) => ({
    paragraph: {},
    request: {
      fontSize: "large",
      fontWeight: "600"
    },
    icon: {
      color: theme.palette.secondary.light,
      fontSize: "4rem",
      width: "auto",
      height: "auto"
    }
  }),
  {name: "UpExpiredSession"}
)(({classes}) => {
  const {t} = useTranslation();
  return (
    <>
      <Box paddingTop={5} marginBottom={5}>
        <Paper elevation={2}>
          <Box padding={3} marginBottom={3}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={2}>
                <Icon className={classes.icon} />
              </Grid>
              <Grid item xs={10}>
                <Typography variant="h3" gutterBottom>
                  <Trans i18nKey="ExpiredSession.request.title" />
                </Typography>
                <Typography variant="body1" gutterBottom color="primary" className={classes.request} component="div">
                  <Trans i18nKey="ExpiredSession.request.text" />
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Paper>
        <Alert severity="info">
          <AlertTitle>{t("ExpiredSession.needHelp.title")}</AlertTitle>
          <Typography variant="body1" gutterBottom className={classes.paragraph} component="div">
            <Trans i18nKey={"ExpiredSession.needHelp.text"} />
          </Typography>
        </Alert>
      </Box>
    </>
  );
});

export default ExpiredSession;
