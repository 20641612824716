import {Grid} from "@material-ui/core";
import moment from "moment";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {formValueSelector} from "redux-form";
import {
  AboriginalTSIOrigin,
  BaseSection,
  DatePicker,
  fromId,
  Gender,
  isYes,
  LearningNumber,
  TextField,
  toId,
  toYesNo,
  useExcludedSet,
  useGlobal,
  YesNo
} from "up-form";
import {useApplication} from "up-state";
const StudentDetails = ({section, form}) => {
  const {t} = useTranslation();
  const selector = formValueSelector(form);
  const hasDisability = useSelector((state) => selector(state, section + ".disability"));
  const {data: {student: {dateOfBirth} = {}} = {}} = useApplication();
  const {
      groups = [],
      component: {
        SignUp: {disallowMinors}
      }
    } = useGlobal(),
    hasAvetmiss = groups.includes("avetmiss");

  // We reproduce DOB on this form
  const maxDob = disallowMinors && moment().subtract(18, "years"),
    minDob = moment().subtract(120, "years");

  // https://acgedu.atlassian.net/browse/DEV-5612 - in some circumstances we may have not collected DOB with lead (e.g. SOE AU enquiry form), so select here if not already collected
  //  or always needed from config
  const showDateOfBirth = !useExcludedSet().has("StudentDetails.dateOfBirth") || !dateOfBirth;

  return (
    <BaseSection section={section} title={t("Section.StudentDetails.title")}>
      {showDateOfBirth && (
        <Grid item xs={12}>
          <DatePicker
            autoComplete="bday"
            required
            name="dateOfBirth"
            label={t("Section.SignUp.dateOfBirth.label")}
            disableFuture
            inputVariant="outlined"
            variant="inline"
            openTo="year"
            minDate={minDob}
            maxDate={maxDob}
            autoOk
            views={["year", "month", "date"]}
            format="DD/MM/yyyy"
            helperText={t("Section.SignUp.dateOfBirth.help")}
          />
        </Grid>
      )}
      <Grid item xs={12} sm={4}>
        <TextField
          label={t("Section.StudentDetails.alternativeEmail.label")}
          type="email"
          autoComplete="email"
          name="alternativeEmail"
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField label={t("Section.StudentDetails.workPhone.label")} name="workPhone" autoComplete="tel" fullWidth />
      </Grid>
      <Grid item xs={12}>
        <Gender label={t("Section.StudentDetails.gender.label")} name="gender" required />
      </Grid>
      {!hasAvetmiss && (
        <>
          <Grid item xs={12} sm={6}>
            <LearningNumber fullWidth label={t("Section.StudentDetails.usi.label")} name="usi" variant="both" />
          </Grid>
          <Grid item xs={12}>
            <AboriginalTSIOrigin
              label={t("Section.StudentDetails.aboriginalTSIOrigin.label")}
              name="aboriginalTSIOrigin"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <YesNo label={t("Section.StudentDetails.disability.label")} name="disability" fullWidth />
          </Grid>
          <Grid item xs={12} sm={10} style={{alignSelf: "flex-end"}}>
            <TextField
              fullWidth
              disabled={hasDisability !== "yes"}
              name="disabilityDescription"
              label={t("Section.StudentDetails.disabilityDescription.label")}
            />
          </Grid>
        </>
      )}
    </BaseSection>
  );
};

export function mapToApplication({
  aboriginalTSIOrigin,
  alternativeEmail,
  dateOfBirth,
  disability,
  disabilityDescription,
  gender,
  previouslyStudied,
  usi,
  workPhone
}) {
  return {
    student: {
      dateOfBirth: dateOfBirth && moment(dateOfBirth).format("YYYY-MM-DD"),
      alternativeEmailAddress: alternativeEmail,
      genderId: toId(gender),
      nsiNumber: usi,
      workPhone: workPhone
    },
    health: {
      disability: isYes(disability),
      disabilityDescription: isYes(disability) ? disabilityDescription : undefined
    },
    education: {
      isPreviousUpStudent: isYes(previouslyStudied)
    }
  };
}

export function mapFromApplication(
  {
    student: {alternativeEmailAddress, dateOfBirth, genderId, nsiNumber, workPhone} = {},
    health: {disability, disabilityDescription} = {},
    education: {isPreviousUpStudent} = {}
  },
  {metadata: {genders}}
) {
  return {
    dateOfBirth: (dateOfBirth && moment(dateOfBirth)) || undefined,
    alternativeEmail: alternativeEmailAddress,
    disability: toYesNo(disability),
    disabilityDescription: disabilityDescription,
    gender: fromId(genders, genderId),
    previouslyStudied: toYesNo(isPreviousUpStudent),
    nsiNumber: nsiNumber,
    workPhone: workPhone
  };
}

StudentDetails.propTypes = {
  form: PropTypes.string.isRequired,
  section: PropTypes.string.isRequired
};

export default StudentDetails;
