import {green, orange, lightBlue, red} from "@material-ui/core/colors";
import {responsiveFontSizes} from "@material-ui/core";
import {createTheme} from "@material-ui/core";
import {merge} from "lodash";
import {lighten, darken} from "@material-ui/core/styles/colorManipulator";

const defaultPalette = {
  success: green,
  warning: orange,
  error: red,
  info: lightBlue
};

const allHeaders = {
  fontFamily: "SimplonNorm",
  fontWeight: 800,
  lineHeight: 2.0
};

// A root theme shared by all child pages

const rootDefaults = {
  spacing: 16,
  typography: {
    fontFamily: ["Open Sans", "sans-serif"].join(","),
    useNextVariants: true,
    h1: {...allHeaders},
    h2: {...allHeaders},
    h3: {...allHeaders},
    h4: {...allHeaders},
    h5: {...allHeaders},
    h6: {...allHeaders}
  },
  palette: {
    primary: {
      main: "#006A90"
    },
    secondary: {
      main: "#555"
    },
    ...defaultPalette
  },
  status: {
    danger: "red"
  },

  overrides: {
    MuiLink: {
      root: {
        whiteSpace: "nowrap"
      }
    },
    MuiFormLabel: {
      root: {
        lineHeight: "1.25em",
        fontWeight: "bold"
      }
    },
    MuiButton: {
      root: {
        fontWeight: "600",
        borderRadius: "2em",
        lineHeight: "2.75em",
        whiteSpace: "nowrap"
      }
    },
    MuiToggleButton: {
      root: {
        "&.Mui-disabled": {
          opacity: "50%"
        }
      }
    },
    MuiTypography: {
      root: {
        "& dt": {
          flexBasis: "20%",
          textAlign: "left",
          padding: "2px 4px",
          fontWeight: "bold"
        },
        "& dd": {
          flexBasis: "70%",
          flexGrow: 1,
          margin: 0,
          padding: "2px 4px"
        },
        "& dl": {
          display: "flex",
          flexFlow: "row wrap"
        }
      }
    },
    UpFieldSet: {
      legend: {
        fontWeight: "bold"
      }
    }
  }
};

const themeByProvider = {
  soe: {
    typography: {
      h1: {fontSize: "32px", fontWeight: 800},
      h2: {fontSize: "24px", fontWeight: 800},
      h3: {fontSize: "20px", fontWeight: 600},
      h4: {fontSize: "16px", fontWeight: 700},
      h5: {fontSize: "14px", fontWeight: 700},
      h6: {fontSize: "12px", fontWeight: 700},
      caption: {fontWeight: 300}
    },
    palette: {
      ...defaultPalette,
      primary: {
        main: "#555"
      },
      secondary: {
        main: "#ED1B2E" //#DC2D27"
      },
      background: {
        default: "#FFFFFF"
      },
      text: {
        primary: "#000000",
        secondary: "#999"
      }
    },
    overrides: {
      MuiButton: {
        containedPrimary: {
          backgroundColor: "#ED1B2E",
          "&:hover": {
            backgroundColor: darken("#ED1B2E", 0.5)
          }
        }
      },
      MuiLink: {
        root: {
          "&:hover": {
            color: "#dc2d27"
          }
        }
      },
      UpSectionHeading: {
        heading: {
          textTransform: "none"
        }
      },
      UpBaseEnrolmentForm: {
        StepperHorizontal: {
          backgroundColor: "rgb(246, 246, 246)"
        }
      }
    }
  },
  "up-online": {
    palette: {
      ...defaultPalette,
      primary: {
        main: "#f97a62"
      },
      secondary: {
        main: "#006990"
      }
    }
  },
  iahealth: {
    typography: {
      h1: {fontSize: "32px", fontWeight: 800},
      h2: {fontSize: "24px", fontWeight: 800},
      h3: {fontSize: "20px", fontWeight: 600},
      h4: {fontSize: "16px", fontWeight: 700},
      h5: {fontSize: "14px", fontWeight: 700},
      h6: {fontSize: "12px", fontWeight: 700},
      caption: {fontWeight: 300}
    },
    palette: {
      ...defaultPalette,
      primary: {
        main: "#725a91"
      },
      secondary: {
        main: "#fe9c7e" //#DC2D27"
      },
      background: {
        default: "#FFFFFF"
      },
      text: {
        primary: "#000000",
        secondary: "#999"
      }
    },
    overrides: {
      MuiButton: {
        containedPrimary: {
          backgroundColor: "#50bd9c",
          "&:hover": {
            backgroundColor: darken("#38ba93", 0.25)
          }
        }
      },
      MuiLink: {
        root: {
          color: "#50bd9c",
          "&:hover": {
            color: "#50bd9c"
          }
        }
      },
      UpSectionHeading: {
        heading: {
          textTransform: "none"
        }
      },
      UpBaseEnrolmentForm: {
        StepperHorizontal: {
          backgroundColor: "rgb(246, 246, 246)"
        }
      }
    }
  },
  colab: {
    typography: {
      fontFamily: "Circular",
      h1: {fontSize: "32px", fontWeight: 800},
      h2: {fontSize: "24px", fontWeight: 800},
      h3: {fontSize: "20px", fontWeight: 600},
      h4: {fontSize: "16px", fontWeight: 700},
      h5: {fontSize: "14px", fontWeight: 700},
      h6: {fontSize: "12px", fontWeight: 700},
      caption: {fontWeight: 300}
    },
    palette: {
      ...defaultPalette,
      primary: {
        main: "#000000"
      },
      secondary: {
        main: "#13ad85" //#DC2D27"
      },
      background: {
        default: "#FFFFFF"
      },
      text: {
        primary: "#000000",
        secondary: "#999"
      }
    },
    overrides: {
      MuiButton: {
        containedPrimary: {
          backgroundColor: "#13ad85",
          "&:hover": {
            backgroundColor: darken("#13ad85", 0.25)
          }
        }
      },
      MuiLink: {
        root: {
          color: "#13ad85",
          "&:hover": {
            color: "#13ad85"
          }
        }
      },
      UpSectionHeading: {
        heading: {
          textTransform: "none"
        }
      },
      UpBaseEnrolmentForm: {
        StepperHorizontal: {
          backgroundColor: "rgb(246, 246, 246)"
        }
      },
      MuiCssBaseline: {
        "@global": {
          "@font-face": {
            fontFamily: "Circular",
            src: 'url("/providers/colab/CircularXXWeb-Book.woff2") format("woff2")'
          }
        }
      }
    }
  }
};

const stripeThemeByProvider = {
  soe: {
    theme: 'stripe',
    rules: {
      '.Input': {
        borderColor: '#d1d1d1',
        borderRadius: '3px',
        boxShadow: '0 0 0 0 rgb(220, 45, 39, 0.25)',
        backgroundColor: '#fff',
        fontFamily: '"Open Sans", sans-serif',
        fontSize: '12px',
      },
      '.Input:focus': {
        borderColor: '#dc2d27',
        boxShadow: '0 0 0 3px rgb(220, 45, 39, 0.25)',
      },
      '.Label': {
        color: '#343642',
        fontFamily: '"Open Sans", sans-serif',
        fontSize: '10px',
        fontWeight: '600',
      },
      '.TermsText': {
        color: '#808285',
        fontFamily: '"Open Sans", sans-serif',
        fontSize: '12px',
      },
      '.Tab': {
        color: '#808285'
      },
      '.Tab--selected': {
        borderColor: '#dc2d27',
        boxShadow: '0 0 0 0 rgb(220, 45, 39, 0.25)',
        color: '#343642'
      },
      '.Tab--selected:focus': {
        color: '#343642',
        borderColor: '#dc2d27',
        boxShadow: '0 0 0 3px rgb(220, 45, 39, 0.25)'
      },
      '.Tab:active': {
        borderColor: '#dc2d27',
        boxShadow: '0 0 0 3px rgb(220, 45, 39, 0.25)',
        color: '#343642'
      },
      '.TabIcon--selected': {
        fill: '#343642'
      },
      '.TabLabel--selected': {
        color: '#343642'
      }
    }
  }
}

const rootTheme = createTheme(rootDefaults);

export {rootTheme};
export function createProviderTheme(provider) {
  console.debug("Creating theme for " + provider);
  const theme = responsiveFontSizes(createTheme(merge({}, rootDefaults, themeByProvider[provider]))),
    {
      palette: {
        type,
        tonalOffset,
        background: {paper},
        primary: {main}
      }
    } = theme;
  const calculatedTheme = merge(theme, {
    overrides: {
      MuiPaper: {
        ...[...Array(10)]
          .map((v, i) => ({
            backgroundColor: type === "dark" ? lighten(paper, (tonalOffset * i) / 16) : darken(paper, (tonalOffset * i) / 16)
          }))
          .reduce((a, v, i) => {
            a[`elevation${i}`] = v;
            return a;
          }, {})
      },
      MuiTypography: {
        root: {
          "& ul": {
            listStyle: "none",
            "& li::before": {
              content: '"\\2022"',
              color: main,
              fontWeight: "bold",
              display: "inline-block",
              width: "1em",
              marginLeft: "-1em",
              fontSize: "2em",
              verticalAlign: "middle"
            }
          },
          "& br": {
            marginBottom: theme.spacing(1)
          }
        }
      }
    }
  });
  console.debug("theme", calculatedTheme);
  return calculatedTheme;
}

export function createStripeTheme(provider) {
  return stripeThemeByProvider[provider];
}
