import {Stepper, withStyles} from "@material-ui/core";
import PropTypes from "prop-types";
import {Skeletize, useGlobal} from "up-form";
const Agreement_Colab = withStyles((theme) => {
  return {
    root: {
      ...theme.typography.body1,
      "& h1": {...theme.typography.h1},
      "& h2": {...theme.typography.h2},
      "& h3": {...theme.typography.h3},
      "& p": {...theme.typography.body1},

      counterReset: "list-0 list-1 list-2",

      "& ol": {
        lineHeight: "1.5em"
      },

      "& ol.continue,& ol.continue-1,& ol.continue-2 ": {
        listStyle: "none !important"
      },

      "& ol.continue>li": {
        counterIncrement: "list-0"
      },

      "& ol.continue-1>li": {
        counterIncrement: "list-1"
      },
      "& ol.continue-2>li": {
        counterIncrement: "list-2"
      },

      "& ol.continue>li::marker": {
        content: "counter(list-0, decimal) '.\\00a0'"
      },
      "& ol.continue-1>li::marker": {
        content: "counter(list-1, lower-alpha) '.\\00a0'"
      },
      "& ol.continue-2>li::marker": {
        content: "counter(list-2, lower-roman) '.\\00a0'"
      },

      "& ol.alpha,& ol ol": {
        listStyle: "lower-alpha"
      },
      "& ol.roman>li, & ol ol ol": {
        listStyle: "lower-roman"
      }
    }
  };
})(({component: Component = Stepper, classes, renderPage = (index, children) => <>{children}</>, ...other}) => {
  const {
    links: {terms}
  } = useGlobal();
  function Link({href, rel = "noopener noreferrer", target = "_blank", children, ...other}) {
    return (
      <a href={href} rel={rel} target={target} {...other}>
        {children || href}
      </a>
    );
  }
  return (
    <Skeletize rows={20} width="50em">
      <Component className={classes.root} {...other}>
        {renderPage(
          0,
          <>
            <h3>Acceptance</h3>
            <p>Colab is a trading name of House of Learning. You will gain a nationally recognised qualification awarded by House of Learning*.</p> 
            
            <p>*House of Learning is a registered training organisation (RTO 21583).All applicants will need to meet the entry and IT requirements.</p>
            
            <h3>Terms and Conditions</h3>
            <p>Terms and Conditions apply to every student enrolled with Colab. Read them carefully before you accept. In completing the Application for Enrolment, and ticking the box below, you are confirming that you have read, understood and accepted these <Link target="_blank" rel="noopener noreferrer" href={terms}> Terms and Conditions.</Link></p>
            
            <h3>Declaration</h3>
            <p>I declare that to the best of my knowledge, all information supplied on, and with, this enrolment form is true and complete.</p>
            <p>I have read, understood and agree to abide by the <Link target="_blank" rel="noopener noreferrer" href={terms}>terms and conditions of enrolment</Link> and I consent to the disclosure of personal information as described in the terms and conditions.</p>
            </>
            )}
      </Component>
      </Skeletize>
      );
});

export default Agreement_Colab;
Agreement_Colab.propTypes = {
  /**
   * render page of markup
   */
  renderPage: PropTypes.func
};