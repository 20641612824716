import React, { useEffect } from 'react';
import { useGlobal, fromLabel, toId } from "up-form";
import { formValueSelector } from "redux-form";
import { useSelector } from 'react-redux';
import { useMetadata } from "up-state";

const ZipProductModal = ({ section, form, amount }) => {
    const { isDev, zip: { tenantKey } = {} } = useGlobal();
    const {
        data: {
            paymentProviders
        } = {},
    } = useMetadata();

    const selector = formValueSelector(form);
    let paymentProviderState = useSelector((state) => selector(state, `${section}.paymentProvider`));

    // Enable Zip Widgets and Assets
    useEffect(() => {
        if (paymentProviderState?.id === toId(fromLabel(paymentProviders, "Zip AU"))) {
            const script = document.createElement('script');
            script.src = 'https://static.zip.co/lib/js/zm-widget-js/dist/zip-widget.min.js';
            script.async = true;
            document.body.appendChild(script);

            return () => {
                document.body.removeChild(script);
            }
        }
    }, [paymentProviderState, paymentProviders]);

    return (
        <div
            style={{ cursor: 'pointer' }}
            id="zip-product-widget"
            data-zm-widget="popup"
            data-zm-region="au"
            data-env={isDev ? "sandbox" : "production"}
            data-zm-merchant={tenantKey}
            data-zm-price={amount}
            data-zm-asset="productwidget"
            data-zm-popup-asset="termsdialog"
        >
        </div>
    )
};

export default ZipProductModal;