import React, {useEffect} from "react";
import {makeStyles} from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import {useGlobal} from "up-form";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    //marginBottom: '1em',
    backgroundColor: theme.palette.background.default
  },
  colorPrimary: {
    color: theme.palette.getContrastText(theme.palette.background.default)
  },
  title: {
    flexGrow: 1,
    textTransform: "none"
  },
  logo: {
    maxHeight: "4em",
    objectFit: "contain"
  }
}));

const OnlineAppBar = React.forwardRef(({title}, ref) => {
  const classes = useStyles(),
    {provider, logo, providerFavicon} = useGlobal(),
    {t} = useTranslation();
  useEffect(() => {
    document.title = [t("App.fullName"), title].filter((v) => !!v).join(" - ");
    const favicon = document.getElementById("favicon");
    if (favicon && providerFavicon) {
      favicon.href = providerFavicon;
    } else if (favicon && logo) {
      favicon.href = logo;
    }
  }, [t, title, logo, providerFavicon]);
  return (
    <AppBar
      color="primary"
      classes={{root: classes.root, colorPrimary: classes.colorPrimary}}
      position="sticky"
      elevation={1}
      ref={ref}
    >

      <Toolbar>
        {logo && <img className={classes.logo} src={logo} alt={provider} />}
        <Typography variant="h6" align="right" className={classes.title}>
          {t("App.fullName")}
        </Typography>
      </Toolbar>
    </AppBar>
  );
});

export default OnlineAppBar;
