import {Box, CircularProgress, Grid, Grow, Paper, Typography, withStyles} from "@material-ui/core";
import QualifyIcon from "@material-ui/icons/BallotTwoTone";
import CheckIcon from "@material-ui/icons/CheckTwoTone";
import PaymentIcon from "@material-ui/icons/PaymentTwoTone";
import InvoiceIcon from "@material-ui/icons/ReceiptTwoTone";
import CompleteIcon from "@material-ui/icons/StarTwoTone";
import CheckParamsIcon from "@material-ui/icons/ThumbsUpDownTwoTone";
import {Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator} from "@material-ui/lab";
import moment from "moment";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import UpApi from "up-api";
import {fromId, fromLabel, toCourseIntake, toId, useGlobal, useQuery} from "up-form";
import {
  applicationSession,
  createApplication,
  createOrder,
  createPaymentSession,
  useApplication,
  useCourses,
  useMetadata
} from "up-state";
import ErrorRedirect from "../error/ErrorRedirect";

const statusLocation = "Online Learning";

function TouchlessStart({classes}) {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {
    provider,
    links: {paymentApproved: approved, paymentDeclined: declined, paymentCancelled: cancelled}
  } = useGlobal();
  const params = useQuery();
  const {
    cid: productCRMId,
    lid: leadCRMId,
    pmid: methodId,
    ppid: paymentProviderId,
    idat: paymentPlanStartDate,
    ifrq: paymentPlanFrequencyId,
    imtd: installmentMethodId,
    tot: totalAmountQuoted
  } = params;
  const {
    pending: applicationPending,
    error: applicationError,
    data: {opportunity: {opportunityCRMId, sessionId: applicationSessionId} = {}} = {}
  } = useApplication();
  const {pending: coursesPending, error: coursesError, data: courses} = useCourses(provider);
  const {
    pending: invoicePending,
    error: invoiceError,
    data: {invoiceNumber, totalAmount} = {}
  } = useSelector((state) => state.createOrder || {});
  const {
    pending: paymentSessionPending,
    error: paymentSessionError,
    data: {id: paymentSessionId} = {}
  } = useSelector((state) => state.createPaymentSession || {});
  const {
    pending: metadataPending,
    error: metadataError,
    data: metadata,
    data: {statusLocations, paymentMethods, paymentProviders, paymentInstallmentMethods} = {}
  } = useMetadata();
  //const {pending: orderPending, error: orderError, data: {invoiceNumber} = {}} = use();

  const busy = metadataPending || coursesPending || applicationPending || invoicePending || paymentSessionPending;
  const [stepError, setStepError] = useState();
  const error = metadataError || applicationError || coursesError || invoiceError || paymentSessionError || stepError;
  const steps = {
    checkParameters: {
      condition: paymentInstallmentMethods && paymentMethods && courses,
      icon: CheckParamsIcon,
      action: async () => {
        // validate parameters against CRM data
        const isRequired = (param) => (param ? false : "Is required");
        const isKnownId = (lookup) => (param) => {
          try {
            return isRequired(param) || (fromId(lookup, param) && false);
          } catch (e) {
            return e.message;
          }
        };
        const isPlan = methodId === "809730001"; //"Payment Plan";
        const paramErrors = Object.entries({
          tot: [isRequired, (tot) => (!!tot && !/^\d+(\.\d*)?$/.test(tot) ? `${tot} should be a dollar amount` : undefined)], // will check actual amount in paymentSession
          cid: [
            isRequired,
            (cid) => {
              try {
                toCourseIntake({productCRMId: cid}, courses);
              } catch (e) {
                return `No intake available for ${cid} with ${provider}`;
              }
            }
          ],
          lid: [isRequired],
          pmid: [isKnownId(paymentMethods)],
          ppid: [isKnownId(paymentProviders)],
          ...(isPlan && {
            idat: [
              isRequired,
              (idat) => (moment(idat, "YYYY-MM-DD", true).isValid() ? false : `${idat} must in format YYYY-MM-DD`)
            ],
            ifrq: [isKnownId(paymentProviders)],
            imtd: [isKnownId(paymentInstallmentMethods)]
          })
        })
          .flatMap(([param, validators]) =>
            validators.map((validator) => {
              const err = validator(params[param]);
              return err && `${param}: ${err}`;
            })
          )
          .filter((v) => !!v);
        if (paramErrors.length > 0)
          setStepError({statusCode: 400, message: `Unable to enrol due to parameter errors:\n${paramErrors.join("\n")}`});
        else setStepName("qualify");
      }
    },
    qualify: {
      condition: statusLocations && courses && !(opportunityCRMId || applicationPending),
      icon: QualifyIcon,
      action: async () => {
        const {course: {intakeList: [{intakeCRMId}] = []} = {}} =
          (courses && productCRMId && toCourseIntake({productCRMId}, courses)) || {};

        const {
          value: {opportunity: {sessionJwt} = {}}
        } = await dispatch(
          createApplication({
            opportunity: {
              statusLocationId: toId(fromLabel(statusLocations, statusLocation)),
              intakeCRMId,
              leadCRMId
            },
            payment: {
              methodId,
              installmentMethodId,
              paymentPlanFrequencyId,
              paymentPlanStartDate,
              paymentProviderId
            }
          })
        );
        // TODO: DRY this (from form)
        if (sessionJwt) {
          // we have an initial auth token as it's a new application (no previous application under this identity)
          UpApi.configure({accessToken: sessionJwt});
          dispatch(applicationSession.fulfilled({opportunityId: opportunityCRMId, token: sessionJwt})); // fake that we've already fetched to save a request
          console.log("Initial application, verification not needed");
        } else {
          console.log("Application already made under this identity, verification needed");
          history.replace("verification");
        }
        setStepName("invoice");
      }
    },
    invoice: {
      condition: opportunityCRMId && !(invoiceNumber || invoicePending),
      icon: InvoiceIcon,
      action: async () => {
        try {
          await dispatch(createOrder(opportunityCRMId));
          setStepName("paymentSession");
        } catch (err) {
          setStepError({...err, message: "An order is already in progress"});
        }
      }
    },
    paymentSession: {
      condition: invoiceNumber && applicationSessionId && !(paymentSessionId || paymentSessionPending),
      icon: PaymentIcon,
      action: async () => {
        try {
          if (!totalAmountQuoted || Math.trunc(Number.parseFloat(totalAmountQuoted) * 100) === Math.trunc(totalAmount * 100)) {
            try {
              await dispatch(
                createPaymentSession(opportunityCRMId, {
                  callbackUrls: {
                    approved: `${approved}/${applicationSessionId}`,
                    declined: `${declined}/${applicationSessionId}`,
                    cancelled: `${cancelled}/${applicationSessionId}`
                  }
                })
              );

              setStepName("complete");
            } catch (err) {
              setStepError({...err, message: "Unable to create payment session"});
            }
          } else
            setStepError({
              status: "409",
              i18nKey: "TouchlessStart.error.amountMismatch",
              message: `invoice amount: ${totalAmount} but quoted: ${totalAmountQuoted}`
            });
        } catch (err) {
          setStepError({message: "Bad amount"});
        }
      }
    },
    complete: {
      condition: applicationSessionId,
      icon: CompleteIcon
    },
    fail: {}
  };
  const [stepName, setStepName] = useState("checkParameters");
  const step = steps[stepName];
  const stepIndex = Object.keys(steps).indexOf(stepName);

  useEffect(() => {
    (async () => {
      if (step) {
        if (!error && step.condition && step.condition && step.action) {
          step.action();
        }
      } else setStepName("checkParameters");
    })();
  }, [courses, metadata, opportunityCRMId, error, busy, step]);
  const history = useHistory();
  return (
    <Grid container direction="column" alignItems="center" justifyContent="center" style={{height: "100%"}}>
      <Grid item>
        <Grow
          in={stepName && stepName !== "complete"}
          timeout={3000}
          onExited={() => {
            history.replace("/enrolling");
          }}
        >
          <Paper elevation={3} className={classes.root}>
            <Box className="root" p={2}>
              <Typography variant="h1">{t("TouchlessStart.title")}</Typography>
              <Timeline align="alternate">
                {Object.entries(steps)
                  .filter(([name]) => name !== "fail")
                  .map(([name, {icon: Icon}], i, allSteps) => {
                    const isCurrent = name === stepName;
                    const isComplete = i < stepIndex;
                    return (
                      <TimelineItem key={i}>
                        <TimelineSeparator>
                          <TimelineDot variant="outlined" className={classes.dot}>
                            {isCurrent ? (
                              <CircularProgress color="secondary" />
                            ) : (
                              <Icon className={classes.icon} color="secondary" fontSize="large" />
                            )}
                          </TimelineDot>
                          {i < allSteps.length - 1 && <TimelineConnector />}
                        </TimelineSeparator>
                        <TimelineContent>
                          <Typography className={classes.stepText}>
                            {isComplete ? (
                              <>
                                {t(`TouchlessStart.steps.${name}.done`)}
                                <CheckIcon className={classes.completeIcon} fontSize="large" />
                              </>
                            ) : (
                              t(`TouchlessStart.steps.${name}.title`)
                            )}
                          </Typography>
                        </TimelineContent>
                      </TimelineItem>
                    );
                  })}
              </Timeline>
            </Box>
          </Paper>
        </Grow>
        {!!error && (
          <ErrorRedirect
            i18nKey="TouchlessStart.error.applicationError"
            resumeUrl=""
            restartUrl={document.referrer}
            status="400"
            {...error}
          />
        )}
      </Grid>
    </Grid>
  );
}

export default withStyles(({palette}) => ({
  root: {color: palette.background.paper, backgroundColor: palette.text.primary},
  dot: {},
  stepText: {fontSize: "x-large", "& *": {verticalAlign: "middle"}},
  completeIcon: {color: palette.success[500]}
}))(TouchlessStart);
