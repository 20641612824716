import {Button, Grid, Paper} from "@material-ui/core";
import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {page} from "up-analytics";
import {ErrorDetails, useGlobal, useQuery} from "up-form";
import {useApplication} from "up-state";

const ErrorPage = () => {
  const {t} = useTranslation();
  const {status: paramStatus} = useParams();
  const {data: {opportunity: {opportunityCRMId, leadCRMId, intakeCRMId} = {}} = {}} = useApplication();
  const {
    links: {publicUrl}
  } = useGlobal();
  const sessionId = sessionStorage.getItem("up.sessionId");
  const {
    message,
    i18nKey,
    status: queryStatus,
    restartUrl = publicUrl,
    resumeUrl = sessionId && `/enrolling/${sessionId}`
  } = useQuery();
  const status = paramStatus || queryStatus;
  useEffect(() => {
    page("ErrorPage", status, {message});
  }, [status, message]);
  return (
    <Grid container justifyContent="center" alignItems="center" style={{height: "80vh"}}>
      <Paper variant="outlined">
        <ErrorDetails
          statusCode={status}
          status={i18nKey && t(i18nKey)}
          message={message}
          actions={[
            restartUrl && (
              <Button variant="contained" color="inherit" href={restartUrl}>
                {t("ErrorPage.action.restart")}
              </Button>
            ),
            resumeUrl && (
              <Button variant="contained" color="inherit" href={resumeUrl}>
                {t("ErrorPage.action.resume")}
              </Button>
            )
          ]}
          extra={{opportunityCRMId, leadCRMId, intakeCRMId, i18nKey}}
        />
      </Paper>
    </Grid>
  );
};

export default ErrorPage;
