import {Grid} from "@material-ui/core";
import PropTypes from "prop-types";
import {Trans, useTranslation} from "react-i18next";
import {FiUpload} from "react-icons/fi";
import {BaseSection, DocumentsUpload, Typography, useGlobal} from "up-form";

const SupportingDocuments = ({form, section}) => {
  const {t} = useTranslation(),
    {
      formOptions: {
        documents: {accept, maxFileSize}
      }
    } = useGlobal();
  return (
    <BaseSection section={section} title={t("Section.SupportingDocuments.title")}>
      <Grid item xs={12}>
        <DocumentsUpload
          name="files"
          accept={accept}
          maxFileSize={maxFileSize}
          dropzoneLabel={
            <Grid container direction="column" alignItems="center" justifyContent="center">
              <FiUpload size="2em" />
              <Typography variant="h6">{t("Section.SupportingDocuments.dropzone.label")}</Typography>
              <Typography variant="body1">
                <Trans i18nKey="SupportingDocuments.dropzone.accept" components={[<b />]} />
              </Typography>
              <Typography variant="body1">
                {t("Section.SupportingDocuments.dropzone.sizeLimit", {
                  maxSize: maxFileSize
                })}
              </Typography>
            </Grid>
          }
        />
      </Grid>
    </BaseSection>
  );
};

SupportingDocuments.propTypes = {
  form: PropTypes.string.isRequired,
  section: PropTypes.string.isRequired
};

export default SupportingDocuments;
