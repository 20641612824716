/**
 * App entry point, dispatch to each sub app
 */
import MomentUtils from "@date-io/moment";
import {LinearProgress, ThemeProvider} from "@material-ui/core";
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import {createBrowserHistory} from "history";
import i18n from "i18next";
import I18NextXhrBackend from "i18next-xhr-backend";
import React, {Suspense} from "react";
import {initReactI18next} from "react-i18next";
import {Provider} from "react-redux";
import {createLogger} from "redux-logger";
import {init as analyticsInit, trackerMiddleware} from "up-analytics";
import UpApi from "up-api";
import {GlobalProvider} from "up-form";
import {createStore, init as upReducerInit} from "up-state";
import config, {isDev} from "../../config";
import {createProviderTheme} from "../../themes";
import "./App.css";
import ProviderPage from "./ProviderPage";
import {AppInsightsContext} from "@microsoft/applicationinsights-react-js";
import {reactPlugin} from "up-analytics";
import {ErrorDetails} from "up-form";

// Pull a bunch of config settings for this  env
const {
  analytics: {appName, instrumentationKey, trackEvents},
  upApi,
  global: {
    stage,
    googleAnalytics: {googleAnalyticsId},
    component: {persist},
    provider
  }
} = config;
const {baseUri} = upApi;

upReducerInit({context: {providerId: provider}});

const {store} = createStore(
  {
    key: "online-enrol-v2",
    whitelist: persist ? ["createLead", "application", "form"] : []
  },
  {
    middleware: [trackerMiddleware(trackEvents), isDev && createLogger({diff: true})]
  }
);

UpApi.configure({
  baseUri,
  headers: {source: "online-application-v2"},
  scaffold: {
    // TODO: testing, remove!!!!
    get: (uri) => {
      if (stage === "development") {
        //if (/v2\/product.*/.test(uri)) return "/dummy/product.json";
      }
    }
  },
  accessToken: () => {
    const {application: {data: {opportunity: sessionJwt} = {}} = {}} = store || {};
    return sessionJwt;
  }
});

// Use custom history so we can listen for router page events for analytics
const appHistory = createBrowserHistory();
analyticsInit({appName, instrumentationKey, googleAnalyticsId, history: appHistory});

i18n
  .use(I18NextXhrBackend)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    lng: "en",
    fallbackLng: "en",
    interpolation: {
      escapeValue: false
    },
    react: {
      useSuspense: true,
      transKeepBasicHtmlNodesFor: ["br", "strong", "em", "b", "i", "p", "dl", "dt", "dd", "ul", "ol", "li", "mark"]
    }
  });

const App = () => {

  return (
    <Suspense  fallback={<LinearProgress />}>
      <GlobalProvider {...config.global}>
        <AppInsightsContext.Provider value={reactPlugin}>
          <Provider store={store}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <ThemeProvider theme={createProviderTheme(provider)}>
                    {provider ? 
                        <ProviderPage history={appHistory} /> :
                        <ErrorDetails message="Unknown provider" />}
                </ThemeProvider>
            </MuiPickersUtilsProvider>
          </Provider>
        </AppInsightsContext.Provider>
      </GlobalProvider>
    </Suspense>
  );
};

export default App;
