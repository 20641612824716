/**
 * Render a redirect to the error page
 */
import {Redirect} from "react-router-dom";
import PropTypes from "prop-types";

export default function ErrorRedirect(props) {
  return (
    <Redirect
      to={`/error?${["status", "i18nKey", "message", "resumeUrl", "restartUrl"]
        .filter((k) => typeof props[k] === "string")
        .map((k) => `${k}=${encodeURIComponent(props[k])}`)
        .join("&")}`}
    />
  );
}

ErrorRedirect.propTypes = {
  status: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // Http error status
  i18nKey: PropTypes.string, // Key of message transalation
  message: PropTypes.string // Untranslated message
};
