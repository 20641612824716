import {Container, CssBaseline, useTheme} from "@material-ui/core";
import {ThemeProvider} from "@material-ui/core/styles";
import {AppInsightsErrorBoundary} from "@microsoft/applicationinsights-react-js";
import i18n from "i18next";
import React, {useRef} from "react";
import {I18nextProvider, useTranslation} from "react-i18next";
import {IconContext} from "react-icons/lib";
import {Redirect, Route, Router, Switch, useLocation} from "react-router-dom";
import {EditConfig, GlobalProvider, useGlobal} from "up-form";
import {updateUpSessionId} from "up-state";
import config, {clientConfigKey, isDev} from "../../config";
import {createProviderTheme} from "../../themes";
import ErrorPage from "../error/ErrorPage";
import PaymentResult from "../payment/PaymentResult";
import ResumeAferPaymentComplete from "../resume/ResumeAfterPaymentComplete";
import {ResumeEnrol} from "../resume/ResumeEnrol";
import ContentPage from "./ContentPage";
import {Enrol} from "./Enrol";
import Footer from "./Footer";
import OnlineAppBar from "./OnlineAppBar";
import {reactPlugin} from "up-analytics";
import ErrorRedirect from "../error/ErrorRedirect";
import TouchlessStart from "../resume/TouchlessStart";
import Verification from "./Verification";
import ExpiredSession from "./ExpiredSession";
import ResumeAfterZipCheckout from "../resume/ResumeAfterZipCheckout";

const Page = ({headerRef, children}) => {
  const {t} = useTranslation(),
    {pathname, hash} = useLocation(),
    pathNorm = (pathname + hash).split(/[/#]/).filter((v) => !!v && v.match(/^[A-Za-z]+$/)),
    pathKey = pathNorm.join(".") || "home",
    name = t(`Page.${pathKey}`);
  return (
    <GlobalProvider
      page={{
        name,
        path: pathname,
        normalized: pathNorm
      }}
    >
      <OnlineAppBar ref={headerRef} title={name} />
      <Container style={{minHeight: "calc(100vh - 10em)"}}>{children}</Container>
    </GlobalProvider>
  );
};
const JsonEditor = React.lazy(() =>
  import(/* webpackChunkName: "jsoneditor" */ "jsoneditor-react").then(({JsonEditor}) => ({default: JsonEditor}))
);
const ProviderPage = ({history}) => {
  const {
    provider,
    groups = [],
    component: {defaultStep}
  } = useGlobal();
  const header = useRef(null);
  const theme = useTheme();
  /**
   * For pages under a specific provider, we add the namesapce of the provider as the default, falling back to
   * the translations for the groups
   *
   */

  const i18forProvider = i18n.cloneInstance({
    ns: ["translation", ...groups, provider],
    defaultNS: provider,
    fallbackNS: [...groups, "translation"]
  });

  //throw new Error("Shit - disaster");
  return (
    <I18nextProvider i18n={i18forProvider}>
      <CssBaseline />
      <IconContext.Provider value={{attr: {"stroke-width": 3}, color: theme.palette.contrastText}}>
        <ThemeProvider theme={(parent) => createProviderTheme(provider)}>
          <Router history={history}>
            <AppInsightsErrorBoundary onError={() => <ErrorRedirect status="appError" />} appInsights={reactPlugin}>
              <Switch>
                <Route
                  exact
                  path="/touchless"
                  render={() => {
                    updateUpSessionId(null);
                    return (
                      <div
                        style={{
                          backgroundImage: "url(/providers/soe/touchless-bg.webp)",
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "cover",
                          height: "100vh",
                          padding: "auto"
                        }}
                      >
                        <TouchlessStart />
                      </div>
                    );
                  }}
                />
                <Route>
                  <Page headerRef={header}>
                    <Switch>
                      <Route
                        exact
                        path={["/", "/enrol/:productCRMId?"]}
                        render={() => {
                          // Ensure session is clear if we are starting new form
                          if (!defaultStep) updateUpSessionId(null);
                          return <Enrol header={header} defaultStep={defaultStep} />;
                        }}
                      />
                      <Route exact path="/enrolling/:sessionId?" render={() => <ResumeEnrol header={header} />} />
                      <Route path="/payment/approved/:sessionId">
                        <ResumeAferPaymentComplete header={header} />
                      </Route>
                      <Route exact path="/payment/cancelled/:sessionId">
                        <Redirect to="/enrolling" />
                      </Route>
                      <Route path="/payment" component={PaymentResult} />
                      <Route path="/zipCheckout/:sessionId">
                        <ResumeAfterZipCheckout header={header} />
                      </Route>
                      <Route path="/content/:contentKey" render={() => <ContentPage prefix="ContentPage" />} />
                      <Route exact path="/verification">
                        <Verification />
                      </Route>
                      <Route exact path="/expired">
                        <ExpiredSession />
                      </Route>
                      <Route path="/error/:status(\d{3})?" component={ErrorPage} />
                      {isDev && (
                        <Route exact path="/edit/config">
                          <EditConfig config={config} clientConfigKey={clientConfigKey} JsonEditor={JsonEditor} />
                        </Route>
                      )}
                      <Route>
                        <ErrorRedirect status="404" />
                      </Route>
                    </Switch>
                  </Page>
                  <Footer />
                </Route>
              </Switch>
            </AppInsightsErrorBoundary>
          </Router>
        </ThemeProvider>
      </IconContext.Provider>
    </I18nextProvider>
  );
};
export default ProviderPage;
