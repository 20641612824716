import {Grid} from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {formValueSelector} from "redux-form";
import {CurrentEmploymentStatus, BaseSection, IndustryDivision, Occupation, fromId, toId} from "up-form";

const Employment = ({section, form}) => {
  const {t} = useTranslation();
  const prefix = "Section.Employment";
  const selector = formValueSelector(form);

  const {label: currentEmploymentStatus} = useSelector((state) => selector(state, section + ".currentEmploymentStatus") || {});
  const showEmploymentDetails = currentEmploymentStatus && !/(Not employed)|(Unemployed)/.test(currentEmploymentStatus);

  return (
    <BaseSection section={section} title={t(`${prefix}.title`)}>
      <Grid item xs={12}>
        <CurrentEmploymentStatus
          name="currentEmploymentStatus"
          label={t(`${prefix}.currentEmploymentStatus.label`)}
          required
          fullWidth
        />
      </Grid>
      {showEmploymentDetails && (
        <>
          <Grid item xs={12}>
            <Occupation
              name="occupation"
              label={t(`${prefix}.occupation.label`)}
              helperText={t(`${prefix}.occupation.text`)}
              required
            />
          </Grid>

          <Grid item xs={12}>
            <IndustryDivision
              name="industry"
              label={t(`${prefix}.industry.label`)}
              helperText={t(`${prefix}.industry.text`)}
              required
            />
          </Grid>
        </>
      )}
    </BaseSection>
  );
};

Employment.propTypes = {
  form: PropTypes.string.isRequired,
  section: PropTypes.string.isRequired
};

export default Employment;

export function mapToApplication({currentEmploymentStatus, industry, occupation}) {
  return {
    history: {
      industryDivisionId: toId(industry),
      occupationId: toId(occupation)
    },
    opportunity: {
      currentEmploymentStatusId: toId(currentEmploymentStatus)
    }
  };
}

export function mapFromApplication(
  {history: {occupationId, industryDivisionId} = {}, opportunity: {currentEmploymentStatusId} = {}},
  {metadata: {currentEmploymentStatuses, industryDivisions, occupations}}
) {
  return {
    currentEmploymentStatus: fromId(currentEmploymentStatuses, currentEmploymentStatusId),
    industry: fromId(industryDivisions, industryDivisionId),
    occupation: fromId(occupations, occupationId)
  };
}
