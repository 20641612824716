import {Grid, Link} from "@material-ui/core";
import PropTypes from "prop-types";
import {Trans, useTranslation} from "react-i18next";
import {BaseSection, Typography, useGlobal, USI as USIField} from "up-form";
import config from "../../config";

const {
  global: {component: {USI: {overrideValue} = {}} = {}}
} = config;

const USI = ({section}) => {
  const {t} = useTranslation();
  const {
    links: {usi}
  } = useGlobal();
  const prefix = "Section.USI";
  return (
    <BaseSection
      section={section}
      title={t(`${prefix}.title`)}
      style={
        overrideValue && {
          display: "none" // note, still registers field if overriding, just doesn't display otherwise mapping ignores section
        }
      }
    >
      <Grid item xs={12}>
        <Typography variant="body1">
          <Trans i18nKey={`${prefix}.text`} components={[<Link target="_blank" href={usi} />]} />
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <USIField name="usi" label={t(`${prefix}.usi.label`)} fullWidth />
      </Grid>
    </BaseSection>
  );
};

export function mapToApplication({usi}) {
  return {
    student: {
      uniqueStudentIdentifier: overrideValue || usi
    }
  };
}
export function mapFromApplication({student: {uniqueStudentIdentifier} = {}}) {
  return {
    usi: uniqueStudentIdentifier
  };
}
USI.propTypes = {
  form: PropTypes.string.isRequired,
  section: PropTypes.string.isRequired
};

export default USI;
