import {Box} from "@material-ui/core";
import {merge} from "lodash";
import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {useParams} from "react-router";
import {Message, toCourseIntake, useQuery} from "up-form";
import {
  application,
  createApplication,
  applicationSession,
  useApplication,
  useCourses
} from "up-state";
import ErrorRedirect from "../error/ErrorRedirect";
import OnlineEnrolmentForm from "../form/OnlineEnrolmentForm";
import UpApi from "up-api";
/**
 * Enrolment form, initialising from query params if provided
 * @param {*} param0
 */
export const Enrol = ({header, defaultStep, children}) => {
  const {t} = useTranslation();
  const {pid: productId, iid, lid: leadCRMId, cid} = useQuery();
  const selectStepIndex = /^\d+$/.test(defaultStep) && parseInt(defaultStep);
  const {productCRMId = cid} = useParams();
  const {data: courses, error: coursesError} = useCourses();
  const {data: currentApplication} = useApplication();
  const {opportunity: {opportunityCRMId, intakeCRMId = iid} = {}} =
    currentApplication || {};
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const [warning, setWarning] = useState(null);
  const [loading, setLoading] = useState(
    productCRMId || intakeCRMId || productId || leadCRMId
  );

  let [inprogress, setInProgress] = useState(false);

  useEffect(() => {
    (async () => {
      if (coursesError) {
        setError({message: coursesError.message});
      } else if (courses) {
        if (intakeCRMId || productCRMId || productId) {
          // with preselected intake, check it exists in current list, or find the first intake if given productId or productCRMId
          const {intake: {intakeCRMId: foundIntakeCRMId} = {}} =
            toCourseIntake({intakeCRMId, productCRMId, productId}, courses) ||
            {};
          if (foundIntakeCRMId) {
            // Intake found, set details in application state
            if (opportunityCRMId) {
              setInProgress(true);
              // We have a current opportunity in application, no need for a round trip, just save in application
              dispatch(
                application.fulfilled(
                  merge(
                    {
                      opportunity: {
                        intakeCRMId: foundIntakeCRMId
                      }
                    },
                    currentApplication
                    )
                    )
                    );
                    setLoading(false);
                    setInProgress(false);
            } else if (leadCRMId) {
              // There is no current opportunity in application we qualify here as the first tab (now Checkout) needs opportunity information to render
              setLoading(false)
              setInProgress(true);
              const {
                value: {opportunity: {sessionJwt} = {}}
              } = await dispatch(
                createApplication({
                  opportunity: {
                    statusLocationId: "809730002", //online learning
                    intakeCRMId: foundIntakeCRMId,
                    leadCRMId
                  }
                })
              );
              if (sessionJwt) {
                setInProgress(false);
                // we have an initial auth token as it's a new application (no previous application under this identity)
                UpApi.configure({accessToken: sessionJwt});
                dispatch(applicationSession.fulfilled({opportunityId: opportunityCRMId, token: sessionJwt})); // fake that we've already fetched to save a request
                console.debug("Initial application, verification not needed");
              }
            }
          } else {
            setWarning({
              status: "404",
              message: `Course intake not found: iid=${intakeCRMId}`
            });
          }
        }
      }
    })();

    // Note: omit currentApplication from deps coz we don't want to listen for change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productCRMId, intakeCRMId, productId, courses, dispatch]);

  warning && console.warn(warning); // may want to render something but for time being just log

  return (
    <>
      {!loading && !error && (
        <OnlineEnrolmentForm
          leadId={leadCRMId}
          headerContainer={header}
          inprogress={inprogress}
          defaultStep={selectStepIndex || defaultStep}
        />
      )}
      {loading && !error && <Box p={3}>{children}</Box>}
      {error && <ErrorRedirect {...error} />}
      <Message open={!!loading} variant="busy" message={t("Enrol.busy")} />
    </>
  );
};

Enrol.propTypes = {
  defaultStep: PropTypes.any, // Index of step to start form wizard on
  header: PropTypes.any, // Component ref to append step headings to
  sessionId: PropTypes.string //SessionIf to initialise from (null to force new session) or it will use sessionStorage
};
