import React, { useEffect, useState } from "react";
import {Alert} from "@material-ui/lab";
import {useTranslation} from "react-i18next";
import {useLocation, useParams} from "react-router-dom";
import ErrorRedirect from "../error/ErrorRedirect";
import {ResumeEnrol} from "./ResumeEnrol";
import {createZipCharge, useApplication} from "up-state";
import {useDispatch, useSelector} from "react-redux";
import {Message} from "up-form";
import {Grid} from "@material-ui/core";

/**
 * Resume after checkout complete redirect from Zip
 * @param paymentSessionId param contains an id created for completion
 */
const ResumeAfterZipCheckout = ({...other}) => {
    const {sessionId} = useParams(),
    dispatch = useDispatch(),
    [chargeCreated, setChargeCreated] = useState(false),
    {t} = useTranslation(),
    {
        pending: zipChargePending,
        error: zipChargeError
    } = useSelector((state) => state.createZipCharge || {}),
    {
        data: {
          opportunity: {
            opportunityCRMId: opportunityId
          } = {},
        } = {},
      } = useApplication(),
    location = useLocation(),
    queryParams = new URLSearchParams(location.search),
    result = queryParams.get('result'),
    checkoutId = queryParams.get('checkoutId'),
    resultApproved = result?.localeCompare("approved", undefined, { sensitivity: 'base' }) === 0,
    resultCancelled = result?.localeCompare("cancelled", undefined, { sensitivity: 'base' }) === 0,
    resultDeclined = result?.localeCompare("declined", undefined, { sensitivity: 'base' }) === 0,
    resultReferred = result?.localeCompare("referred", undefined, { sensitivity: 'base' }) === 0;
    
    useEffect(() => {
        if (resultApproved && opportunityId && checkoutId && !chargeCreated && !zipChargeError) {
            dispatch(createZipCharge(opportunityId, { id: checkoutId }));
            setChargeCreated(true);
        }
    }, [resultApproved, opportunityId, checkoutId, chargeCreated, zipChargeError, dispatch]);

  return (
    <>
      {sessionId && ((resultApproved && !zipChargeError && zipChargePending === false) || (resultCancelled || resultDeclined || resultReferred)) ? (
            <ResumeEnrol
                sessionId={sessionId}
                resumeStep="acceptPayment"
                applicationUpdate={{
                    payment: resultApproved ? {
                        firstPaymentTransactionId: sessionId
                    } : {}
                }}
                {...other}
            >
                {resultApproved && <Alert severity="success">{t("ResumeEnrol.paymentComplete.text")}</Alert>}
            </ResumeEnrol>
        ) : resultApproved && zipChargePending ? (
            <>
                <Grid item xs={12} style={{ paddingTop: '50px' }}>
                    <Alert severity="warning">{t("ResumeEnrol.zipPending.alert")}</Alert>
                </Grid>
                <Message open={zipChargePending} variant="busy" message={t("ResumeEnrol.zipPending.loadingMessage")} />
            </>
        )
        : zipChargeError && (
            <ErrorRedirect i18nKey={t("ResumeEnrol.zipPending.errorMessage")}/>
        )}
    </>
  );
};

export default ResumeAfterZipCheckout;