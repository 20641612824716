import {Stepper, withStyles} from "@material-ui/core";
import PropTypes from "prop-types";
import {Skeletize, useGlobal} from "up-form";
const Agreement_IAH = withStyles((theme) => {
  return {
    root: {
      ...theme.typography.body1,
      "& h1": {...theme.typography.h1},
      "& h2": {...theme.typography.h2},
      "& h3": {...theme.typography.h3},
      "& p": {...theme.typography.body1},

      counterReset: "list-0 list-1 list-2",

      "& ol": {
        lineHeight: "1.5em"
      },

      "& ol.continue,& ol.continue-1,& ol.continue-2 ": {
        listStyle: "none !important"
      },

      "& ol.continue>li": {
        counterIncrement: "list-0"
      },

      "& ol.continue-1>li": {
        counterIncrement: "list-1"
      },
      "& ol.continue-2>li": {
        counterIncrement: "list-2"
      },

      "& ol.continue>li::marker": {
        content: "counter(list-0, decimal) '.\\00a0'"
      },
      "& ol.continue-1>li::marker": {
        content: "counter(list-1, lower-alpha) '.\\00a0'"
      },
      "& ol.continue-2>li::marker": {
        content: "counter(list-2, lower-roman) '.\\00a0'"
      },

      "& ol.alpha,& ol ol": {
        listStyle: "lower-alpha"
      },
      "& ol.roman>li, & ol ol ol": {
        listStyle: "lower-roman"
      }
    }
  };
})(({component: Component = Stepper, classes, renderPage = (index, children) => <>{children}</>, ...other}) => {
  const {
    links: {privacy}
  } = useGlobal();
  function Link({href, rel = "noopener noreferrer", target = "_blank", children, ...other}) {
    return (
      <a href={href} rel={rel} target={target} {...other}>
        {children || href}
      </a>
    );
  }
  return (
    <Skeletize rows={20} width="50em">
      <Component className={classes.root} {...other}>
        {renderPage(
          0,
          <>
            <p>By enrolling in a course with the Institute of Allied Health (the Institute), you agree to the following terms and 
            conditions, whether you have read the terms and conditions or not.</p> 
            
            <p>Upon submission of my enrolment, payment (or part thereof) of my tuition, and acceptance into the course, the Institute 
            will send me, or make available to me, the course material for my course. I understand that access to my course material 
            is progressive, based on my progress through the course. I understand my rights and obligations as a student as outlined in 
            the Student Handbook and this Student Agreement, that these may be updated from time to time and I will remain 
            bound by the terms including any varied terms. The information I have provided in my course application is truthful and 
            will be viewed by the Institute and/or its nominee only, in accordance with the Institute’s 
             <Link target="_blank" rel="noopener noreferrer" href={privacy}> Privacy Notice</Link>. I accept the 
            decision of the Institute regarding my initial application for enrolment and my ongoing enrolment as final. </p>
        
          <ol>
          <li>
          <p>
          I acknowledge and understand that the Institute incurs initial and ongoing costs associated with my enrolment, whether I 
          progress academically in my course or not. I agree to the Institute’s Course Cancellation Policy and agree that should I 
          cancel from the course within 30 days of my enrolment, then I will receive a full refund of monies paid to the Institute in 
          respect of my course enrolment. I understand that if I cancel from the course more than 30 days after my enrolment, but prior 
          to three months after my enrolment date then I am liable for 50% of the total cost of the course. I further agree that if I 
          discontinue my course three months or more after the date of my initial enrolment, I will be liable for the full cost of the 
          course, irrespective of whether I have paid that due amount in tuition at the date of my cancellation, and irrespective of my 
          academic progress in the course at the date of cancellation.
          </p>
          </li>
 
            <li>
            <p>
            I acknowledge and understand that I have a maximum of five (5) years from the date of enrolment to complete my Institute course. 
            At the conclusion of the five-year period, my enrolment will be deemed concluded and a Statement of Attainment will be issued for 
            any units completed.
            </p>
            </li>

            <li>
            <p>
            I understand that should I wish to cancel my course at any time, I must submit my request to cancel by email to the nominated email 
            address in the Course Cancellation Policy, outlined in the Student Handbook. I further agree that should my tuition fees become two 
            or more months in arrears I am immediately liable for the full cost of my course enrolment.
            </p>
            </li>
          
          <li>
          <p>
          I acknowledge that I need to have access to a modern computer to undertake my studies, and the system should have a minimum of the 
          following specifications:
            <ul>
            <li>Windows 10 or later Operating System</li>
            <li>Microsoft Office 2013 or later software programs</li>
            <li>Internet access ADSL or better, with a minimum of 1.2Mbps download/1.2Mbps upload</li>
            <li>Access to a web cam and microphone</li>
            </ul>
            <p>All Books, Workbooks, Readings, Assessments, Logos, Concepts, Videos, Documents and Recordings that are made available to me or 
            received by me from the Institute are protected by copyright and I agree that I will not sell, reproduce, copy, loan or otherwise 
            make available any item to any other person or institution.</p> 
            
            <p>Institute courses and programs are subject to changing accreditation and academic standards, and in particular the Institute’s 
            courses that are subject to the jurisdiction of the ‘National Vocational Education and Training Regulator Act 2011.’ I acknowledge 
            that the course or program I am enrolled in may change from time to time due to academic and/or accreditation requirements and as 
            a consequence of such changes there may be changes to the structure and or curriculum of the course.</p>
            </p>
            </li>
            
            <li>
            <p>
            I understand that whilst my course does not have fixed semesters or assessment due dates (self-paced), it is my responsibility to 
            reasonably progress in my course and complete my course within five (5) years of the start date. If for whatever reason my course 
            curriculum changes, I will endeavor to complete the course within the teach out period. If I am unable to complete the course within 
            the teach out period, and my period of enrolment exceeds the period in which I would be due a refund or the maximum time limit for 
            the course (five years), I understand that I am ineligible for any refund.
            </p>
            </li>
          
            
            <li>
            <p>
            I understand that personal information provided by me will be used to either obtain and/or validate a Unique Student Identifier for 
            me which is required to issue my certificate for the qualification and enables the Institute to report my progress and completion 
            of the course. I also confirm that the personal information I have provided is true and correct and understand that the information 
            will be reported to government authorities as required under RTO reporting arrangements. I acknowledge that if I have advised that I 
            require assistance with Language, Literacy or Numeracy or that I have a disability or special need and require additional learning 
            support, an Institute Educational Adviser will contact me for an assessment and discussion of my requirements. 
            </p>
            </li>
          
          
            <li>
            <p>I acknowledge that the relationship between the Institute and myself shall not constitute a relationship of partnership or joint venture 
            or any other relationship where the Institute or agent of the Institute can be held responsible in any way for any actions or words made 
            by me.</p>
            
            <p>Any usage of the Marks and the Name Institute of Allied Health and any other goodwill established thereby shall ensure to the exclusive 
            benefit of the owner of the Marks and Name. I will follow the policies of the Institute unless approval to do otherwise is received in 
            writing. I will at all times respect the good name of the Institute and will maintain the highest possible standards of confidentiality, 
            ethics and behaviour in all relevant practices concerning my clients and the Institute, and will at no time take any action or utter any 
            words which might in any way damage the Institute or its Agents. </p>
            </li>
            </ol>  
            </>
            )}
      </Component>
      </Skeletize>
      );
});

export default Agreement_IAH;
Agreement_IAH.propTypes = {
  /**
   * render page of markup
   */
  renderPage: PropTypes.func
};
