import {Grid, Grow} from "@material-ui/core";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {formValueSelector} from "redux-form";
import {
  AboriginalTSIOrigin,
  BaseSection,
  CitizenshipStatus,
  Country,
  fromId,
  fromLabel,
  isYes,
  Language,
  Optional,
  SpokenEnglish,
  toId,
  toYesNo,
  useGlobal,
  YesNo
} from "up-form";
import {toCourseIntake, Year} from "up-form";
import {useApplication, useCourses, useMetadata} from "up-state";

const LanguageAndCulture = ({section, form}) => {
  const {t} = useTranslation();
  const prefix = "Section.LanguageAndCulture";
  const {data: {languages} = {}} = useMetadata();
  const {journey} = useGlobal();
  const selector = formValueSelector(form);
  const {label: citizenshipStatus} = useSelector((state) => selector(state, section + ".citizenshipStatus")) || {};
  const {label: countryOfBirth} = useSelector((state) => selector(state, section + ".countryOfBirth")) || {};

  const {
    data: {opportunity: {intakeCRMId} = {}}
  } = useApplication();
  const {data: courses} = useCourses();
  const {course: {isHEOrVETFunded} = {}} = (courses && intakeCRMId && toCourseIntake({intakeCRMId}, courses)) || {};

  const showCitizenship = journey !== "soe-nz" || /None of the Above/i.test(citizenshipStatus);
  const showYearOfArrival = countryOfBirth && !/Australia/i.test(countryOfBirth) && isHEOrVETFunded; // DEV-4921: show year of arrival if VET funded and not Australian birth

  return (
    <BaseSection section={section} title={t(`${prefix}.title`)}>
      <Grid item xs={6}>
        <Country label={t(`${prefix}.countryOfBirth.label`)} name="countryOfBirth" fullWidth required />
      </Grid>
      <Grid item xs={6}>
        <Grow in={showYearOfArrival} unmountOnExit mountOnEnter timeout={1000}>
          <Year name="yearOfArrival" label={t(`${prefix}.yearOfArrival.label`)} required fullWidth />
        </Grow>
      </Grid>
      <Optional name="LanguageAndCulture.indigenousIdentifier">
        <Grid item xs={12}>
          <AboriginalTSIOrigin label={t(`${prefix}.indigenousIdentifier.label`)} name="indigenousIdentifier" required />
        </Grid>
      </Optional>
      <Grid item xs={12}>
        <CitizenshipStatus label={t(`${prefix}.citizenshipStatus.label`)} name="citizenshipStatus" required />
      </Grid>
      <Grow in={showCitizenship} timeout={1000}>
        <Grid item xs={12} style={!showCitizenship && {padding: 0}}>
          {showCitizenship && (
            <Country label={t(`${prefix}.countryOfCitizenship.label`)} name="countryOfCitizenship" fullWidth required />
          )}
        </Grid>
      </Grow>
      <Grid item xs={12} sm={6}>
        <Language
          defaultValue={languages && fromLabel(languages, "English")}
          label={t(`${prefix}.languageSpokenAtHome.label`)}
          name="languageSpokenAtHome"
          helperText={t(`${prefix}.languageSpokenAtHome.help`)}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <SpokenEnglish label={t(`${prefix}.spokenEnglish.label`)} name="spokenEnglish" required />
      </Grid>
      <Grid item xs={12} sm={6}>
        <YesNo label={t(`${prefix}.requiresEnglishHelp.label`)} name="requiresEnglishHelp" required />
      </Grid>
      <Grid item xs={12} sm={6}>
        <YesNo
          label={t(`${prefix}.requiresLanguageLiteracyNumeracyHelp.label`)}
          name="requiresLanguageLiteracyNumeracyHelp"
          required
        />
      </Grid>
    </BaseSection>
  );
};

LanguageAndCulture.propTypes = {
  form: PropTypes.string.isRequired,
  section: PropTypes.string.isRequired
};

export default LanguageAndCulture;

export function mapToApplication(
  {
    indigenousIdentifier,
    ausResident,
    citizenshipStatus,
    countryOfBirth,
    countryOfCitizenship,
    languageSpokenAtHome,
    requiresEnglishHelp,
    requiresLanguageLiteracyNumeracyHelp,
    spokenEnglish,
    yearOfArrival
  },
  {metadata: {languages}}
) {
  return {
    residency: {
      australianResident: isYes(ausResident),
      countryOfBirthId: toId(countryOfBirth),
      indigenousIdentifierId: toId(indigenousIdentifier),
      citizenshipStatusId: toId(citizenshipStatus),
      countryOfCitizenshipId: toId(countryOfCitizenship),
      yearOfArrival: yearOfArrival || undefined
    },
    language: {
      requiresEnglishHelp: isYes(requiresEnglishHelp),
      requiresLanguageLiteracyNumeracyHelp: isYes(requiresLanguageLiteracyNumeracyHelp),
      languageSpokenAtHomeId: toId(languageSpokenAtHome),
      spokenEnglishId: toId(spokenEnglish)
    }
  };
}

export function mapFromApplication(
  {
    residency: {
      australianResident,
      countryOfBirthId,
      countryOfCitizenshipId,
      citizenshipStatusId,
      indigenousIdentifierId,
      yearOfArrival
    } = {},
    language: {requiresEnglishHelp, languageSpokenAtHomeId, spokenEnglishId, requiresLanguageLiteracyNumeracyHelp} = {}
  },
  {metadata: {aboriginalTSIOrigins, citizenshipStatuses, countries, languages, spokenEnglishLevels}}
) {
  //const languageSpokenAtHome = languageSpokenAtHomeId ? fromId(languages, languageSpokenAtHomeId) : fromLabel(languages,"English");
  return {
    ausResident: toYesNo(australianResident),
    countryOfBirth: fromId(countries, countryOfBirthId),
    countryOfCitizenship: fromId(countries, countryOfCitizenshipId),
    citizenshipStatus: fromId(citizenshipStatuses, citizenshipStatusId),
    indigenousIdentifier: fromId(aboriginalTSIOrigins, indigenousIdentifierId),
    languageSpokenAtHome: fromId(languages, languageSpokenAtHomeId),
    requiresEnglishHelp: toYesNo(requiresEnglishHelp) || undefined,
    requiresLanguageLiteracyNumeracyHelp: toYesNo(requiresLanguageLiteracyNumeracyHelp) || undefined,
    spokenEnglish: fromId(spokenEnglishLevels, spokenEnglishId),
    yearOfArrival
  };
}
