import {Grid} from "@material-ui/core";
import PropTypes from "prop-types";
import {Trans, useTranslation} from "react-i18next";
import {BaseSection, TextField, Typography} from "up-form";

const DigitalSignature = ({form, section}) => {
  const {t} = useTranslation();

  return (
    <BaseSection section={section} title={t("Section.DigitalSignature.title")}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography>
            <Trans t={t} i18nKey="Section.DigitalSignature.text" />
          </Typography>
        </Grid>
        <Grid item>
          <TextField label={t("Section.DigitalSignature.label")} name="signatureName" required />
        </Grid>
      </Grid>
    </BaseSection>
  );
};

DigitalSignature.propTypes = {
  form: PropTypes.string.isRequired,
  section: PropTypes.string.isRequired
};

export default DigitalSignature;

export function mapToApplication({signatureName}) {
  return {
    opportunity: {
      signatureName
    }
  };
}

export function mapFromApplication({opportunity: {signatureName} = {}}) {
  return {
    signatureName
  };
}
