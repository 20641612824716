/**
 * Display a panel of summary information for review
 */
import {Grid, Paper, Step, StepContent, StepLabel, Stepper, withStyles} from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {formValueSelector} from "redux-form";
import {acceptance} from "redux-form-validators";
import {BaseSection, Checkbox, Currency, Message, Typography} from "up-form";
import {useApplication, useCourses, useInvoicePricing, useMetadata} from "up-state";
import Summary from "../content/Summary";
import {mapFromApplication as mapPaymentFields} from "./Checkout";
import {mapFromApplication as mapCourse} from "./ChooseCourse";
import config from "../../config";

  const {
    global: {
      component: {
        Summary: {pages = []}
      }
    }
  } = config;

const Review = withStyles((theme) => {
  return {
    root: {},
    icon: {},
    label: {
      "&:after": {
        content: ":"
      }
    },
    info: {},
    stepConfirmed: {
      opacity: ".5"
    },
    summary: {
      width:"100%"
    },
    subtext: {
      gridColumn: "2"
    },
    paymentSummary: {
      minWidth: "50%",
      display: "grid",
      gridTemplateColumns: "max-content max-content",
      columnGap: ".5em",
      alignItems: "center",
      "& .amount": {
        justifySelf: "end"
      }
    },
    confirmationRoot: {
      borderColor: theme.palette.secondary.light
    }
  };
})(({section, form, classes, change}) => {
  const {t} = useTranslation();
  const prefix = "Section.Review";
  const selector = formValueSelector(form);
  const confirmed = useSelector((state) => selector(state, `${section}.confirmed`) || {});
  const active = pages.findIndex((name) => !confirmed[name]);
  // Global lookup data
  const {data: metadata, pending: pendingMetadata} = useMetadata();
  const {data: courses, pending: coursesPending} = useCourses();

  // Our application form (must be present)
  const {data: application} = useApplication();

  // Map out payment data from api application as we do on the checkout section
  const {method, paymentPlanFrequency, paymentPlanStartDate, paymentProvider} =
    (application && mapPaymentFields(application, {metadata})) || {};

  // Data from invoice pricing
  const {data: pricing, error, pending: pendingPricing} = useInvoicePricing();

  // And the same with course data as we do on course section
  const {
    course: {durationMonths: duration, durationUnit = "months", marketingName, name, studyLoad, workPlacement} = {},
    studyMode: {label: studyModeLabel} = {}
  } = (courses && application && metadata && mapCourse(application, {courses, metadata})) || {};
  const courseName = name || marketingName;

  // Waiting for anything?
  const pending = coursesPending || pendingPricing || pendingMetadata;

  const PaymentSummary = ({pricing = {}}) => {
    const isPaymentPlan = method && /Plan/i.test(method.label || "");
    const {productPriceCurrency, paymentProviders} = pricing;
    const {extendedAmount: discountedTotalAmount, installments} =
      (paymentProviders && paymentProvider && paymentProviders[paymentProvider.label]) || {};
    const {
      noOfPayments,
      installmentAmount,
      paymentPlanDepositAmount: depositAmount
    } = (Array.isArray(installments) &&
      paymentPlanFrequency &&
      installments.find(({paymentFrequency}) => paymentFrequency === paymentPlanFrequency.id)) ||
    {};

    function RenderCurrency({value, ...other}) {
      return (
        <Currency
          component="span"
          formatOptions={{signDisplay: "never"}}
          currency={productPriceCurrency}
          value={Number(value)}
          {...other}
        />
      );
    }

    function SummaryLine({i18nKey, children}) {
      return (
        <>
          <Typography variant="body1">{t(`${prefix}.page.formSummary.${i18nKey}`)}</Typography>
          <Typography component="span" className="amount">
            {children}
          </Typography>
        </>
      );
    }
    return (
      <div className={classes.paymentSummary}>
        {discountedTotalAmount && (
          <SummaryLine i18nKey="discountedTotalAmount">
            <RenderCurrency value={discountedTotalAmount} />
          </SummaryLine>
        )}
        {paymentProvider && <SummaryLine i18nKey="paymentProvider">{paymentProvider.label}</SummaryLine>}
        {isPaymentPlan && (
          <>
            {depositAmount && (
              <SummaryLine i18nKey="depositAmount">
                <RenderCurrency value={depositAmount} />
              </SummaryLine>
            )}
            {installmentAmount && (
              <SummaryLine i18nKey="installmentAmount">
                <RenderCurrency value={installmentAmount} />
              </SummaryLine>
            )}
            {noOfPayments && <SummaryLine i18nKey="noOfPayments">{noOfPayments}</SummaryLine>}
            {paymentPlanFrequency && <SummaryLine i18nKey="paymentPlanFrequency">{paymentPlanFrequency.label}</SummaryLine>}
            {paymentPlanStartDate && <SummaryLine i18nKey="paymentPlanStartDate">{paymentPlanStartDate.format("LL")}</SummaryLine>}
          </>
        )}
      </div>
    );
  };

  // FIXME: using this as a func rather than component as it seems to have a weird effect with form checkbox causing render loop
  function ReviewStep({key, name, children}) {
    const isConfirmed = confirmed && confirmed[name];
    return (
      <Step key={key} expanded completed={isConfirmed}>
        <StepLabel>
          <Typography variant="subtitle1">{t(`${prefix}.page.${name}.step.label`)}</Typography>
        </StepLabel>
        <StepContent orientation="vertical">
          <div className={isConfirmed ? classes.stepConfirmed : ""}>{children}</div>
          <Paper variant="outlined" className={!isConfirmed ? classes.confirmationRoot : ""}>
            <Grid container justifyContent="center">
              <Checkbox
                color="secondary"
                required
                validate={acceptance()}
                name={`confirmed.${name}`}
                label={t(`${prefix}.page.${name}.confirm.label`)}
              />
            </Grid>
          </Paper>
        </StepContent>
      </Step>
    );
  }

  return (
    <BaseSection section={section} title={t("Section.Review.title")}>
      <Stepper orientation="vertical" active={active} className={classes.summary}>
        {ReviewStep({
          key: 0,
          name: pages[0],
          children: (
            <>
              {courseName && (
                <Typography variant="body1">
                  {t(`${prefix}.page.formSummary.qualification`, {
                    qualification: courseName
                  })}
                </Typography>
              )}
              {duration && durationUnit && (
                <Typography variant="body1">
                  {t(`${prefix}.page.formSummary.duration`, {
                    duration: duration,
                    unit: durationUnit
                  })}
                </Typography>
              )}
              <Typography variant="body1">{t(`${prefix}.page.formSummary.courseProvider`)}</Typography>
              {studyModeLabel && (
                <Typography variant="body1">{t(`${prefix}.page.formSummary.studyMode`, {mode: studyModeLabel})}</Typography>
              )}
              {studyLoad && (
                <Typography variant="body1">
                  {t(`${prefix}.page.formSummary.studyLoad`, {
                    count: studyLoad,
                    unit: "hours",
                    period: "week"
                  })}
                </Typography>
              )}
              {workPlacement && (
                <Typography variant="body1">
                  {t(`${prefix}.page.formSummary.workPlacement`, {
                    count: workPlacement,
                    unit: "hours"
                  })}
                </Typography>
              )}
              {pricing && <PaymentSummary pricing={pricing} />}
            </>
          )
        })}
        {pages.length === 2 && ReviewStep({key: 1, name: pages[1], children: <Summary />})}
      </Stepper>

      <Message open={pending} variant="busy" message={t(`${prefix}.busy`)} />
      {error && <Message open={!!error} variant="error" message={t(`${prefix}.error`, {message: error.message})} />}
    </BaseSection>
  );
});

Review.propTypes = {
  onChange: PropTypes.func
};

export default Review;

export function mapToApplication({confirmed}) {
  return {
    declaration: {
      agreeDeclaration: pages.every((page) => confirmed[page])
    }
  };
}

export function mapFromApplication({declaration: {agreeDeclaration} = {}}) {
  return {
    agreeDeclaration: pages.reduce((acc, page) => ({[page]: agreeDeclaration, ...acc}), {})
  };
}
