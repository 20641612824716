import React from 'react';
import { Button, Dialog, DialogContent, IconButton, Typography, withStyles } from '@material-ui/core';
import CloseIcon from "@material-ui/icons/Close";

const ZipPricingPopup = withStyles((theme) => {
    return {
        popupLink: {
            color: theme.palette.secondary.main,
            textTransform: 'none',
            textDecoration: 'underline',
            fontStyle: 'italic',

            '&:hover': {
                backgroundColor: 'transparent',
                textDecoration: 'underline'
            }
        }
    }
})(({classes}) => {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    return (
        <>
            <Button disableRipple className={classes.popupLink} onClick={handleClickOpen} >Learn more</Button>
            <Dialog open={open} onClose={handleClose}>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    style={{position: 'absolute', right: 8}}
                    >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers style={{backgroundColor:'#eee6fe'}}>
                    <Typography gutterBottom>
                        *Available to approved applicants only and
                        subject to completion of satisfactory credit
                        check. Minimum monthly repayments are
                        required. Paying only the minimum monthly
                        repayment amount will generally not repay a
                        purchase within the interest free period. A
                        monthly account fee of $9.95 will also apply and
                        a one-off establishment fee may apply for new
                        customers. Any balance outstanding at the
                        expiry of the interest free period will be charged
                        at the standard variable interest rate, 25.9% per
                        annum as at 1 June 2023. Other charges may be
                        payable, see T&Cs. Interest, fees and charges
                        subject to change. Terms & Conditions apply and
                        are available on application. See your contract
                        for further details. Credit provided by ZipMoney
                        Payments Pty Ltd (ABN 58 164 440 993),
                        Australian Credit Licence Number 441878)
                        <br/>
                        <br/>
                        <b>More information on ZIP Money:</b>
                        <br/>
                        <a href="https://zip.co/au/zip-money" target="_blank" rel="noreferrer">https://zip.co/au/zip-money</a>
                    </Typography>
                </DialogContent>
            </Dialog>
        </>
    )})

export default ZipPricingPopup;