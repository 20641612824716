import {FormHelperText, Grid, IconButton} from "@material-ui/core";
import AddCircle from "@material-ui/icons/AddCircle";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import moment from "moment";
import PropTypes from "prop-types";
import {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {FieldArray, formValueSelector} from "redux-form";

import {
  BaseSection,
  EducationLevel,
  fromId,
  Optional,
  PriorEducationalAchievementIdentifier,
  QualificationLevel,
  toId,
  Typography,
  useGlobal,
  Year
} from "up-form";
import {useMetadata} from "up-state";

// Hack for DEV-5518, substutute NZ labels for qualifications in AU enum and discard not in keys
const mapAUToNZQualifications = {
  "44997cab-0404-ec11-b6e5-00224810bc7f": "NCEA Level 2 or 6th Form Certificate",
  "4e997cab-0404-ec11-b6e5-00224810bc7f": "NCEA Level 3 or Bursary or Scholarship - Completed in last 3 yrs",
  "50997cab-0404-ec11-b6e5-00224810bc7f": "NCEA Level 3 or Bursary or Scholarship - Completed more than 3yrs ago",
  "56997cab-0404-ec11-b6e5-00224810bc7f": "Non Award - Int",
  "64997cab-0404-ec11-b6e5-00224810bc7f": undefined, // keep au label
  "66997cab-0404-ec11-b6e5-00224810bc7f": undefined,
  "68997cab-0404-ec11-b6e5-00224810bc7f": undefined,
  "6a997cab-0404-ec11-b6e5-00224810bc7f": undefined,
  "6c997cab-0404-ec11-b6e5-00224810bc7f": undefined
};

// DEV-5756 Ids that are non-'high school' qualifications
const nonHighSchoolQualifications = new Set([
  "40997cab-0404-ec11-b6e5-00224810bc7f", //  "BKSB"
  "52997cab-0404-ec11-b6e5-00224810bc7f", //  "Foundation - Aus"
  "54997cab-0404-ec11-b6e5-00224810bc7f", //  "Non Award - Aus"
  "56997cab-0404-ec11-b6e5-00224810bc7f", //  "Non Award - Int"
  "58997cab-0404-ec11-b6e5-00224810bc7f", //  "VE Aus Certificate II, III & IV"
  "5a997cab-0404-ec11-b6e5-00224810bc7f", //  "VE Aus AQF 5 & 6"
  "5e997cab-0404-ec11-b6e5-00224810bc7f", //  "HE Sub Bachelor - Aus Qual"
  "60997cab-0404-ec11-b6e5-00224810bc7f", //  "HE Bachelor - Aus Qual"
  "62997cab-0404-ec11-b6e5-00224810bc7f", //  "HE Postgraduate - Aus Qual"
  "64997cab-0404-ec11-b6e5-00224810bc7f", //  "HE Sub Bachelor - Int Qual"
  "66997cab-0404-ec11-b6e5-00224810bc7f", //  "HE Bachelor - Int Qual"
  "68997cab-0404-ec11-b6e5-00224810bc7f", //  "HE Postgraduate -  Int  Qual"
  "6a997cab-0404-ec11-b6e5-00224810bc7f", //  "Work Experience - Responsibility"
  "6c997cab-0404-ec11-b6e5-00224810bc7f", //  "Work Experience - Occupation"
  "6e997cab-0404-ec11-b6e5-00224810bc7f", //  "STAT"
  "70997cab-0404-ec11-b6e5-00224810bc7f" //  "Test"
]);

const Qualifications = ({section, form, change}) => {
  const {t} = useTranslation();
  const prefix = "Section.Qualifications";
  const selector = formValueSelector(form);
  const {journey} = useGlobal();
  const {data: {priorEducationalAchievementIdentifiers = []} = {}} = useMetadata();
  const completedQualifications = useSelector((state) => selector(state, `${section}.completedQualifications`) || []);
  const highestQualificationLevel = useSelector((state) => selector(state, `${section}.highestQualificationLevel`));
  const dateOfBirth = useSelector((state) => selector(state, "signup.dateOfBirth"));

  const dispatch = useDispatch();
  const localAchievementIdentifier =
    journey === "soe-nz"
      ? {
          id: priorEducationalAchievementIdentifiers.find(({label}) => /International/.test(label)).id, // international journey specific default
          label: "New Zealand"
        }
      : priorEducationalAchievementIdentifiers.find(({label}) => /Australian/.test(label));
  useEffect(() => {
    if (!completedQualifications || completedQualifications.length === 0)
      dispatch(
        change(`${section}.completedQualifications`, [
          {
            achievementIdentifier: localAchievementIdentifier
          }
        ])
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // omit deps so we suggest one on first render but can be deleted

  const renderSubFields = (achievement, index, fields) => (
    <Grid container item key={index} spacing={1} direction="row" alignItems="start">
      <Grid item xs={6}>
        <EducationLevel
          name={`${achievement}.completedQualification`}
          label={t(`${prefix}.completedQualification.label`)}
          required
        />
      </Grid>
      <Grid item xs={5}>
        <PriorEducationalAchievementIdentifier
          name={`${achievement}.achievementIdentifier`}
          label={t(`${prefix}.achievementIdentifier.label`)}
          required
          transform={
            journey === "soe-nz"
              ? (options) => {
                  return [
                    {label: localAchievementIdentifier.label, value: localAchievementIdentifier}, // DEV-5715 - add special NZ one that returns same id as International
                    ...options.filter(({label}) => !/Equivalent/.test(label)) // DEV-5715 - remove Equivalent for NZ
                  ];
                }
              : undefined
          }
        />
      </Grid>
      <Grid item>
        <IconButton color="primary" data-testid={`${section}-remove-achievement-${index}`} onClick={() => fields.remove(index)}>
          <RemoveCircle fontSize="large" />
        </IconButton>
      </Grid>
    </Grid>
  );
  const renderAchievements = ({fields, meta: {error}}) => (
    <>
      <Grid container spacing={2} direction="column">
        {fields && fields.map(renderSubFields)}
        <Grid container item spacing={2} justifyContent="center">
          <IconButton
            color="primary"
            data-testid={`${section}-add-achievement`}
            onClick={() =>
              fields &&
              fields.push({
                achievementIdentifier: localAchievementIdentifier
              })
            }
          >
            <AddCircle fontSize="large" />
          </IconButton>
        </Grid>
      </Grid>
      {error && (
        <FormHelperText error required>
          {error}
        </FormHelperText>
      )}
    </>
  );

  return (
    <BaseSection section={section} title={t(`${prefix}.title`)}>
      <Grid item xs={12}>
        <Typography variant="caption">{t(`${prefix}.completedQualifications.label`)}</Typography>
      </Grid>
      <Grid item xs={12}>
        <FieldArray
          name="completedQualifications"
          component={renderAchievements}
          validate={(
            completedQualifications, // DEV-5756 - if highest qual is set and was a high school year (.e.g Y12) we need at least one acheivement
            {qualifications: {highestQualificationLevel: {id: highestQualificationLevelId} = {}} = {}}
          ) =>
            highestQualificationLevelId &&
            nonHighSchoolQualifications.has(highestQualificationLevelId) &&
            (!completedQualifications || completedQualifications.length === 0) &&
            t(`${prefix}.completedQualifications.error.oneOrMoreRequired`)
          }
        />
      </Grid>

      <Grid item xs={6}>
        <Optional name="Qualifications.highestQualificationLevel">
          <QualificationLevel
            transform={
              journey === "soe-nz"
                ? (options) =>
                    options
                      .filter(({id}) => id in mapAUToNZQualifications)
                      .map(({id, label}) => ({id, label: mapAUToNZQualifications[id] || label}))
                : undefined
            }
            fullWidth
            name="highestQualificationLevel"
            label={t(`${prefix}.highestQualificationLevel.label`)}
          />
        </Optional>
      </Grid>
      {highestQualificationLevel && (
        <Grid item xs={6}>
          <Year
            fullWidth
            first={dateOfBirth && moment(dateOfBirth).year()}
            name="finalYearOfHighestQualification"
            label={t(`${prefix}.finalYearOfHighestQualification.label`)}
            required
          />
        </Grid>
      )}
    </BaseSection>
  );
};

Qualifications.propTypes = {
  form: PropTypes.string.isRequired,
  section: PropTypes.string.isRequired
};

export default Qualifications;

export function mapToApplication({completedQualifications, highestQualificationLevel, finalYearOfHighestQualification}) {
  return {
    education: {
      completedQualifications: (completedQualifications || []).map(({completedQualification, achievementIdentifier}) => ({
        qualificationId: toId(completedQualification),
        priorEducationalAchievementId: toId(achievementIdentifier)
      })),
      highestQualificationCompletedOrAttemptedId: toId(highestQualificationLevel),
      finalYearOfHighestQualification: highestQualificationLevel && finalYearOfHighestQualification
    }
  };
}

export function mapFromApplication(
  {education: {completedQualifications, highestQualificationCompletedOrAttemptedId, finalYearOfHighestQualification} = {}},
  {metadata: {educationLevels, priorEducationalAchievementIdentifiers, qualificationLevels}}
) {
  return {
    completedQualifications: (completedQualifications || []).map(({qualificationId, priorEducationalAchievementId}) => ({
      completedQualification: fromId(educationLevels, qualificationId),
      achievementIdentifier: fromId(priorEducationalAchievementIdentifiers, priorEducationalAchievementId)
    })),
    highestQualificationLevel: fromId(qualificationLevels, highestQualificationCompletedOrAttemptedId),
    finalYearOfHighestQualification: finalYearOfHighestQualification
  };
}
