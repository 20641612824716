import React, { useState } from 'react';
import { PaymentElement, useStripe, useElements } from "@stripe/react-stripe-js";
import {Grid, Button, CircularProgress, Box} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import { useGlobal, Checkbox, Typography, fromId, toLabel } from 'up-form';
import { change, formValueSelector } from "redux-form";
import {createStripeIntentAndSubscription, useMetadata} from "up-state";
import { Trans, useTranslation } from "react-i18next";
import {acceptance} from "redux-form-validators";
import {Alert} from "@material-ui/lab";

const StripePaymentElement = ({ section, form }) => {
    const { t } = useTranslation();
    const prefix = "Section.StripePaymentPlan";
    const stripe = useStripe();
    const elements = useElements();
    const dispatch = useDispatch();
    const [success, setSuccess] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [stripePending, setStripePending] = useState(false);
    const selector = formValueSelector(form);
    const {data: application} = useSelector((state) => state.application || {});
    let {
        opportunity: {opportunityCRMId: opportunityId} = {},
        student: {firstName, lastName, emailAddress, studentUPId} = {},
        payment: {paymentPlanFrequencyId, paymentPlanStartDate, invoiceId} = {},
      } = application || {};
    const {
        provider,
        links: { home, paymentPlan },
        stripeProductId
    } = useGlobal();
    const {
        data: {
          paymentFrequencies
        } = {},
      } = useMetadata();
    const {data: {clientId: clientSecret, subscriptionScheduleId, error: stripeError} = {}, pending} = useSelector((state) => state.createStripeIntentAndSubscription || {});
    const isConfirmed = useSelector(
        (state) => selector(state, `${section}.confirmed`) || false
      );

    const handleError = (error) => {
        setErrorMessage(error.message);
        setStripePending(false);
    };

    const handleClick = async (event) => {
      event.preventDefault();
      
      if (!stripe || !elements) {
        return;
      };
      
      const result = await elements.submit();
      setStripePending(true);

        if (result.error) {
            handleError(result.error);
        } 
        else {
            const { error, setupIntent } = await stripe.confirmSetup({
                elements,
                clientSecret,
                confirmParams: {
                return_url: home
                },
                redirect: "if_required"
            });
            
            if (error) {
                // This point is only reached if there's an immediate error when
                // confirming the setup. Show the error to your customer (for example, payment details incomplete)
                handleError(error);
            } 
            else if (setupIntent.payment_method) {
                dispatch(change(form, `${section}.paymentMethodId`, setupIntent.payment_method));
                const date = paymentPlanStartDate && new Date(paymentPlanStartDate);
                const unixTimestamp = Math.floor(date.getTime() / 1000);
                dispatch(
                  createStripeIntentAndSubscription(
                      {
                        provider: provider,
                        name: `${firstName} ${lastName}`,
                        email: emailAddress,
                        studentId: studentUPId,
                        paymentPlanStartDate: unixTimestamp,
                        productId: stripeProductId,
                        frequency: toLabel(fromId(paymentFrequencies, paymentPlanFrequencyId)),
                        invoiceNumber: invoiceId,
                        opportunityId: opportunityId,
                        clientId: setupIntent.payment_method,
                        createType: "Subscription",
                        paymentPlanTermsAccepted: isConfirmed ? true : false
                      }
                    )
                  );
                setSuccess(true);
                setErrorMessage(null);
                setStripePending(false);
            }
        }
    }

    return (
        <Grid item xs={12} md={5}>
            <Typography variant="h1" gutterBottom>
              Payment Details
            </Typography>
            <Typography variant="subtitle1" gutterBottom style={{paddingBottom: '20px'}}>
              <Trans t={t} i18nKey={`${prefix}.paymentDetailsDescription`} />
            </Typography>
            <PaymentElement />
            <Grid item xs={12} style={{paddingTop: '20px'}}>
              <Checkbox
                required
                validate={acceptance()}
                color="secondary"
                name="confirmed"
                disabled={success}
                label={
                  <Typography style={{fontSize: '12px'}}>I confirm I have read, understood and agree to the <a rel="noreferrer" style={{color:"black"}} target="_blank" href={paymentPlan}><span style={{fontWeight: 'bold'}}>Payment Terms and Conditions.</span></a></Typography>
                }
              />
            </Grid>
            <Grid item xs={12} style={{paddingBottom: "20px", paddingTop: "20px"}}>
                <Button
                  fullWidth
                  color="primary"
                  disabled={!stripe || !elements || !isConfirmed || success || pending || stripePending}
                  variant="contained"
                  data-name="StripePaymentPlan.proceed"
                  onClick={handleClick}
                  style={{ borderRadius: "3px", fontSize: "14px", textTransform: "none" }}
                >
                {stripePending || pending ? (
                  <Box display="flex" alignItems="center" spacing={1}>
                    <CircularProgress size={24} /> 
                    <Box ml={1}> Set up payments </Box>
                  </Box>
                  ) : ("Set up payments"
                  )}
                </Button>
            </Grid>
            { subscriptionScheduleId && <Alert severity='success'>Your subscription was successfully created.</Alert>}
            { (errorMessage || stripeError) && <Alert severity='error'>{(errorMessage || stripeError)}</Alert>}
        </Grid>
    );
};

StripePaymentElement.propTypes = {};

export default StripePaymentElement;