import {Box} from "@material-ui/core";
import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Redirect, useLocation, useParams} from "react-router-dom";
import {fromLabel, Message, toId, useGlobal} from "up-form";
import {useApplication, useMetadata, updateApplication,} from "up-state";
import {Enrol} from "../app/Enrol";
import ErrorRedirect from "../error/ErrorRedirect";
import {useDispatch} from "react-redux";

/**
 * Component
 *
 * 1 Obtains session token jwt given session id (if expired API will error and component will redirect)
 * 2 loads/updates current application (if applicationUpdate issues a PATCH to advance opportunity state)
 * 3 Resumes form at step passed as prop, or derived from opportunity state
 */

let calledUpdatePayment = false;
export const ResumeEnrol = ({resumeStep: requestedStep, applicationUpdate, sessionId: sessionIdProp, children, ...other}) => {
  const {sessionId: sessionIdParam} = useParams();
  const dispatch = useDispatch();
  const sessionId = sessionIdProp || sessionIdParam;
  const {t} = useTranslation();
  const {hash} = useLocation();
  const {provider} = useGlobal();
  const isIAH = "iahealth" === provider;
  const {
    data: {
      opportunity: {
        signatureName: signature,
        opportunityCRMId: opportunityId
      } = {},
      declaration: { agreeTerms: agreedTerms } = {},
    } = {},
  } = useApplication();

  if (opportunityId && (applicationUpdate?.payment?.hasOwnProperty("firstPaymentTransactionId") || applicationUpdate?.payment?.hasOwnProperty("paymentPlanId")) && applicationUpdate && !calledUpdatePayment) {
    calledUpdatePayment = true

    if(isIAH && agreedTerms) { 
      applicationUpdate.opportunity = {};
      applicationUpdate.opportunity.signatureName = {};
      applicationUpdate.opportunity.signatureName = signature;
      applicationUpdate.declaration = {};
      applicationUpdate.declaration.agreeTerms = {}; 
      applicationUpdate.declaration.agreeTerms = agreedTerms;
    }

    dispatch(
      updateApplication({
        opportunityId,
        body: applicationUpdate
      })
    );
  }

  const resumeStep = requestedStep || (hash && hash.slice(1));
  const {
    error: applicationError,
    data: application,
    data: {payment: {payNowOrLaterId, invoiceId} = {}} = {},
    pending: pendingApplication
  } = useApplication({sessionId, update: applicationUpdate});
  const [initialised, setInitialised] = useState(false);
  const {error: metadataError, data: {payNowOrLaterOptions} = {}, pending: pendingMetadata} = useMetadata();
  const pending = pendingApplication || pendingMetadata;
  const noInvoiceError = payNowOrLaterId && // Error if somehow we are trying to resume with no invoice when paylater has been set
    payNowOrLaterOptions &&
    payNowOrLaterId === toId(fromLabel(payNowOrLaterOptions, "Pay Later")) &&
    !invoiceId && {
      i18nKey: "ResumeEnrol.payLater.notificationFailed"
    };
  const error = applicationError || metadataError || noInvoiceError;

  useEffect(() => {
    if (!pending && !error && application) {
      setInitialised(true);
    }
  }, [application, pending, error, sessionId]);
  return (
    <>
      {pending && !error && !initialised && <Box p={3}>{children}</Box>}
      {initialised && (
        <Enrol defaultStep={resumeStep} {...other}>
          {children}
        </Enrol>
      )}
      {(!initialised && applicationError && <Redirect to={"/expired"} />) ||
        (error && ( // Note: the form will handle errors that occur after initialisation, allowing retries
          // Failed to reload application form
          <ErrorRedirect {...error} />
        ))}
      <Message open={!initialised} variant="busy" message={t("ResumeEnrol.busy")} />
    </>
  );
};

ResumeEnrol.propTypes = {
  /**
   * Step name or index to resume from
   * Defaults to inferring from opportunity state fetched
   * */
  resumeStep: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

  /**
   * Payload to update the student application with. By default the student application is simply fetched
   */
  applicationUpdate: PropTypes.object
};
