/**
 * User sign up prior to full application
 */
import {Grid, Grow} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import moment from "moment";
import PropTypes from "prop-types";
import {useState} from "react";
import {useTranslation} from "react-i18next";
import {BaseSection, DatePicker, fromLabel, fromPhone, TextField, toId, toPhone, TypedPhone, Typography, useGlobal, Optional} from "up-form";
import config from "../../config";
import {format} from "redux-form-validators";

const SignUp = ({section, form, course: {marketingName, name} = {}}) => {
  const {t} = useTranslation();
  const {component: {SignUp: {disallowMinors, checkPhoneCountry, validatePhoneNumber} = {}} = {}} = useGlobal();
  const maxDob = disallowMinors && moment().subtract(18, "years"),
    minDob = moment().subtract(120, "years");
  const displayName = marketingName || name;
  const [countryValidationError, setCountryValidationError] = useState();

  return (
    <BaseSection
      section={section}
      title={displayName ? t("Section.SignUp.withCourse.title", {name: displayName}) : t("Section.SignUp.noCourse.title")}
    >
      <Typography style={{marginBottom: "1em"}} variant="body1">
        {t("Section.SignUp.text")}
      </Typography>
      <Grid container direction="row" spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            autoComplete="given-name"
            label={t("Section.SignUp.firstName.label")}
            name="firstName"
            inputProps={{maxLength: 80}}
            required
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            autoComplete="additional-name"
            label={t("Section.SignUp.middleName.label")}
            name="middleName"
            inputProps={{maxLength: 80}}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            autoComplete="family-name"
            label={t("Section.SignUp.lastName.label")}
            name="lastName"
            inputProps={{maxLength: 80}}
            required
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            autoComplete="email"
            fullWidth
            label={t("Section.SignUp.email.label")}
            type="email"
            name="emailAddress"
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TypedPhone
            required
            fullWidth
            countries={checkPhoneCountry}
            onCountryValidation={validatePhoneNumber && (({valid}) => {
              setCountryValidationError(!valid && t("Section.SignUp.phone.countryInvalid"));
            })}
            inputProps={{minLength: 5}}
            label={t("Section.SignUp.phone.label")}
            FormHelpTextProps={{component: "div"}}
            helperText={
              <Grow mountOnEnter unmountOnExit in={!!countryValidationError} timeout={1000}>
                <Alert severity="warning">{countryValidationError}</Alert>
              </Grow>
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DatePicker
            autoComplete="bday"
            required
            fullWidth
            name="dateOfBirth"
            label={t("Section.SignUp.dateOfBirth.label")}
            disableFuture
            inputVariant="outlined"
            variant="inline"
            openTo="year"
            minDate={minDob}
            maxDate={maxDob}
            autoOk
            views={["year", "month", "date"]}
            format="DD/MM/yyyy"
            helperText={t("Section.SignUp.dateOfBirth.help")}
          />
        </Grid>
        <Optional name="SignUp.postcode">
          <Grid item xs={12} sm={6}>
            <TextField
              autoComplete="postcode"
              fullWidth
              label={t("Section.SignUp.postcode.label")}
              name="postcode"
              inputProps={{maxLength: 4}}
              validate={format({with: /\d{4}/, message: "Invalid postcode"})}
              required
            />
          </Grid>
        </Optional>
      </Grid>
    </BaseSection>
  );
};

SignUp.propTypes = {
  form: PropTypes.string.isRequired,
  section: PropTypes.string.isRequired,
  course: PropTypes.object
};

export default SignUp;

const dateFormat = "YYYY-MM-DD";

export function mapToLead({dateOfBirth, emailAddress, firstName, lastName, middleName, phone, phoneType, postcode}) {
  const {
    global: {
      component: {
        SignUp: {leadSource}
      }
    }
  } = config;
  return {
    ...toPhone({phone, phoneType}),
    dateOfBirth: dateOfBirth && moment(dateOfBirth).format(dateFormat),
    emailAddress: emailAddress,
    enquiryNote: "This enquiry came from the online application form",
    firstName: firstName,
    intention: "Apply",
    middleName: middleName,
    lastName: lastName,
    leadSource,
    recordFor: "onlinelearning",
    studentStatusLocation: "Online Learning",
    postcode: postcode
  };
}

export function mapToApplication(
  {dateOfBirth, emailAddress, firstName, lastName, middleName, phone, phoneType} = {},
  {metadata: {statusLocations}}
) {
  return {
    opportunity: {
      statusLocationId: toId(fromLabel(statusLocations, "Online Learning"))
    },
    student: {
      ...toPhone({phone, phoneType}),
      dateOfBirth: dateOfBirth && moment(dateOfBirth).format(dateFormat),
      emailAddress,
      firstName,
      middleName,
      lastName
    }
  };
}

export function mapFromApplication({student = {}, student: {dateOfBirth, emailAddress, firstName, middleName, lastName} = {}}) {
  return {
    ...fromPhone(student),
    dateOfBirth: (dateOfBirth && moment(dateOfBirth)) || undefined,
    emailAddress,
    firstName,
    lastName,
    middleName
  };
}
