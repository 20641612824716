import React from "react";
import {Grid} from "@material-ui/core";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {formValueSelector} from "redux-form";
import {BaseSection, fromId, isYes, SchoolLevel, toId, toYesNo, useGlobal, Year, YesNo} from "up-form";
const Schooling = ({section, form}) => {
  const {t} = useTranslation();
  const prefix = "Section.Schooling";
  const {journey} = useGlobal();

  const selector = formValueSelector(form);
  const {label: highestCompletedSchoolLevel} = useSelector(
    (state) => selector(state, section + ".highestCompletedSchoolLevel") || {}
  );
  const hasCompletedSchool = highestCompletedSchoolLevel && !/Never/i.test(highestCompletedSchoolLevel);
  return (
    <BaseSection section={section} title={t(`${prefix}.title`)}>
      <Grid item xs={12} sm={6}>
        <SchoolLevel
          name="highestCompletedSchoolLevel"
          label={t(`${prefix}.highestCompletedSchoolLevel.label`)}
          transform={
            journey === "soe-nz" && // DEV-6233: NZ school Year label values are AU+1
            ((options) =>
              options.map((option) => ({
                ...option,
                label: option.label.replace(/(Year)\s*(\d+)/i, (_, y, v) => `${y} ${parseInt(v) + 1}`)
              })))
          }
          required
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        {hasCompletedSchool && <Year fullWidth required name="lastYearOfSchool" label={t(`${prefix}.lastYearOfSchool.label`)} />}
      </Grid>
      <Grid item xs={12} sm={6}>
        <YesNo label={t(`${prefix}.isAtSchool.label`)} name="isAtSchool" required />
      </Grid>
    </BaseSection>
  );
};

Schooling.propTypes = {
  form: PropTypes.string.isRequired,
  section: PropTypes.string.isRequired
};

export default Schooling;

export function mapToApplication({highestCompletedSchoolLevel, isAtSchool, lastYearOfSchool}) {
  return {
    education: {
      isAtSchool: isYes(isAtSchool),
      highestCompletedSchoolLevelId: toId(highestCompletedSchoolLevel),
      lastYearOfSecondarySchool: lastYearOfSchool
    }
  };
}

export function mapFromApplication(
  {education: {highestCompletedSchoolLevelId, isAtSchool, lastYearOfSecondarySchool} = {}},
  {metadata: {schoolLevels}}
) {
  return {
    isAtSchool: toYesNo(isAtSchool),
    highestCompletedSchoolLevel: fromId(schoolLevels, highestCompletedSchoolLevelId),
    lastYearOfSchool: lastYearOfSecondarySchool
  };
}
