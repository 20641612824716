import {Accordion, AccordionDetails, AccordionSummary, Typography, withStyles} from "@material-ui/core";
import ExpandLessOutlined from "@material-ui/icons/ExpandLessOutlined";
import ExpandMoreOutlined from "@material-ui/icons/ExpandMoreOutlined";
import Work from "@material-ui/icons/Work";
import Info from "@material-ui/icons/Info";
import Payment from "@material-ui/icons/Payment";
import Person from "@material-ui/icons/Person";
import Receipt from "@material-ui/icons/Receipt";
import School from "@material-ui/icons/School";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import clsx from "clsx";
import {startCase} from "lodash";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {formValueSelector} from "redux-form";
import upApi from "up-api";
import {Currency} from "up-form";
import {useApplication, useInvoicePricing} from "up-state";

const ApplicationSummary = withStyles((theme) => {
  return {
    root: {},
    headerIcon: {
      color: theme.palette.info.main
    },
    icon: {
      verticalAlign: "middle",
      marginRight: "0.5em"
    },
    info: {},
    summary: {
      display: "grid",
      gridTemplateColumns: "max-content 1fr",
      columnGap: ".5em"
    },
    subtext: {
      gridColumn: "2"
    }
  };
})(({form, classes, className, title, expandUp}) => {
  const selector = formValueSelector(form);
  const {firstName, lastName} = useSelector((state) => selector(state, "signup") || {});
  const {course, studyMode} = useSelector((state) => selector(state, "chooseCourse") || {});
  const {label: studyModeLabel} = studyMode || {};
  const {method, paymentProvider} = useSelector((state) => selector(state, "checkout") || {});
  const {label: paymentMethodLabel} = method || {};
  const {label: paymentProviderLabel} = paymentProvider || {};
  const courseName = course && (course.marketingName || course.name);
  const showSummary = (firstName && lastName) || course;
  const [isUserVerified, setVerified] = useState(false);
  const {data: {student: {firstName: appFirstName, lastName: appLastName} = {}} = {}} = useApplication();
  const {data: finalPrice} = useInvoicePricing();
  const invoiceAmount =
    paymentProvider &&
    finalPrice &&
    finalPrice.paymentProviders &&
    finalPrice.paymentProviders[paymentProviderLabel] &&
    finalPrice.paymentProviders[paymentProviderLabel].extendedAmount;
  useEffect(() => {
    upApi.getAccessToken().then((token) => setVerified(!!token));
  });

  // Trick here = if we haven't got the signup section filled in (e.g. came in from lead) we can still get name from application data after opportunity create
  const fullName = startCase(`${firstName || appFirstName || ""} ${lastName || appLastName || ""}`).trim();

  function SummaryLine({icon: Icon = "span", info, subtext}) {
    return (
      <>
        <Icon className={classes.icon} />
        <Typography variant="subtitle1" className={classes.info}>
          {info}
        </Typography>
        {subtext && (
          <Typography variant="subtitle2" className={classes.subtext}>
            {subtext}
          </Typography>
        )}
      </>
    );
  }
  return (
    <>
      {showSummary && (
        <Accordion elevation={2} defaultExpanded={false} className={className}>
          <AccordionSummary expandIcon={expandUp ? <ExpandLessOutlined /> : <ExpandMoreOutlined />}>
            {title && (
              <Typography variant="body1" className={classes.header}>
                <Info fontSize="large" className={clsx(classes.icon, classes.headerIcon)} />
                {title}
              </Typography>
            )}
          </AccordionSummary>
          <AccordionDetails>
            <aside aria-label="application summary" className={classes.summary}>
              {fullName && <SummaryLine icon={isUserVerified ? VerifiedUser : Person} info={fullName} />}
              {courseName && (
                <SummaryLine
                  icon={School}
                  info={courseName}
                  subtext={`Level ${course.level || ""} ${course.qualificationName || ""}/${course.duration || ""} ${
                    course.durationUnit || ""
                  }`}
                />
              )}
              {studyModeLabel && <SummaryLine icon={Work} info={studyModeLabel} />}
              {paymentMethodLabel && <SummaryLine icon={Payment} info={paymentMethodLabel} />}
              {invoiceAmount && (
                <SummaryLine icon={Receipt} info={<Currency formatOptions={{signDisplay: "auto"}} value={invoiceAmount} />} />
              )}
            </aside>
          </AccordionDetails>
        </Accordion>
      )}
    </>
  );
});

export default ApplicationSummary;
