/**
 * User sign up prior to full application
 */
import React from "react";
import PropTypes from "prop-types";
import {Trans, useTranslation} from "react-i18next";
import {Grid, Link, Typography, withStyles} from "@material-ui/core";
import {BaseSection, useGlobal} from "up-form";
import {mapFromApplication as mapCourse} from "./ChooseCourse";
import {useCourses, useMetadata} from "up-state";
import {useSelector} from "react-redux";
import {fromLabel, toId} from "up-form";

const Complete = withStyles((theme) => {
  return {
    root: {
      display: "grid",
      justifyItems: "center",
      gridGap: ".5em",
      gridTemplateRows: "50% min-content min-content"
    },
    image: {
      width: "100%",
      height: "100%",
      objectFit: "contain"
    },
    text: {
      textAlign: "center"
    },
    noImageRoot: {
      display: "grid",
      justifyItems: "left",
      gridGap: ".5em",
      gridTemplateRows: "50% min-content min-content"
    }
  };
})(({section, form, classes}) => {
  const {t} = useTranslation();
  const {data: courses} = useCourses();
  const {data: metadata} = useMetadata();
  const {data: {payNowOrLaterOptions} = {}} = useMetadata();
  const {
    component: {
      Complete: {
        showCompletePageImage
      } = {}
    },
    links: {faq}
  } = useGlobal();

  // Our application form (must be present)
  const {data: application, data: {student: {emailAddress} = {}, opportunity: {allConditionsMet} = {}} = {}} = useSelector(
    (state) => state.application
  );

  // Grab course info
  const {course: {marketingName, name} = {}} = (courses && application && mapCourse(application, {courses, metadata})) || {};
  const courseName = marketingName || name;
  const {payment: {payNowOrLaterId} = {}} = application || {};
  const isPayLater = payNowOrLaterId === (payNowOrLaterOptions && toId(fromLabel(payNowOrLaterOptions, "Pay Later")));
  
  return (
    <BaseSection section={section} title={t("Section.Complete.title")}>
      <Grid item className={!showCompletePageImage ? classes.noImageRoot : classes.root}>
        {!showCompletePageImage ? (
          <>
            <Typography className={classes.text} variant="body1">
                {t("Section.Complete.pending.featureText", {courseName})}
            </Typography>
            <Typography className={classes.text} variant="body1">
                {t("Section.Complete.pending.text")}
            </Typography>
          </>
        ) : (!isPayLater ? (
          allConditionsMet ? (
            <>
              <img className={classes.image} src="/enrolSuccess.svg" alt={t("Section.Complete.success.imageAlt")} />
              <Typography variant="h1">{t("Section.Complete.success.featureText", {courseName})}</Typography>
              <Typography className={classes.text} variant="body1">
                <Trans i18nKey="Section.Complete.success.text" values={{email: emailAddress}} />
              </Typography>
            </>
          ) : (
            <>
              <img className={classes.image} src="/enrolPending.svg" alt={t("Section.Complete.success.imageAlt")} />
              <Typography style={{marginBottom: "1em"}} variant="h5">
                {t("Section.Complete.pending.featureText", {courseName})}
              </Typography>
              <Typography className={classes.text} variant="body1">
                {t("Section.Complete.pending.text")}
              </Typography>
            </>
          )
        ) : (
          <>
            <img className={classes.image} src="/enrolPending.svg" alt={t("Section.Complete.success.imageAlt")} />
            <Typography style={{marginBottom: "1em"}} variant="h5">
              {t("Section.Complete.payLaterOptionPending.featureText", {courseName})}
            </Typography>
            <Typography className={classes.text} variant="body1">
              {t("Section.Complete.payLaterOptionPending.text")}
            </Typography>
          </>
        ))}
        {faq && (
          <Link href={faq}>
            <Typography variant="body1">{t("Section.Complete.link.faq")}</Typography>
          </Link>
        )}
      </Grid>
    </BaseSection>
  );
});

Complete.propTypes = {
  form: PropTypes.string.isRequired,
  section: PropTypes.string.isRequired
};

export default Complete;
