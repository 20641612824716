import {Grid} from "@material-ui/core";
import {merge} from "lodash";
import PropType from "prop-types";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {formValueSelector} from "redux-form";
import {format} from "redux-form-validators";
import {
  AddressAutoComplete,
  BaseSection,
  Country,
  fromAddress,
  fromLabel,
  isYes,
  State,
  TextField,
  toAddress,
  toYesNo,
  Typography,
  useGlobal,
  YesNo,
  Optional
} from "up-form";
import {useMetadata} from "up-state";

// Most address fields just simple text fields
const SimpleField = ({name, label, ...other}) => <TextField fullWidth name={name} label={label} {...other} />;


const AddressFields = ({preferCountry, prefix, required = false, title, section, change, renderers, normalizers = {}}) => {

  const {t} = useTranslation(),
    {
      component: {addressAutoComplete},
      google: {apiKey}
    } = useGlobal();
  return (
    <>
      <Grid item sm={12}>
        {title && <Typography variant="subtitle1">{title}</Typography>}
      </Grid>
      {addressAutoComplete && (
        <Grid item xs={12}>
          <AddressAutoComplete
            required={required}
            fullWidth
            label={t("Section.Address.autoComplete.label")}
            onChange={(fields) => {
              Object.entries(fields)
                .filter(([field, value]) => !!renderers[field])
                .forEach(([field, value]) => {
                  const normalizer = normalizers[field];
                  change(`${section}.${prefix}.${field}`, normalizer ? normalizer(value) : value);
                });
            }}
            apiKey={apiKey}
            preferCountry={preferCountry}
          />
        </Grid>
      )}
      {Object.entries(renderers)
        .filter(([field, renderer]) => !!renderer)
        .map(([field, renderer], i) => (
          <Grid item xs={12} sm={4} key={i}>
            {renderer({
              name: `${prefix}.${field}`,
              label: t(`Section.Address.${field}.label`)
            })}
          </Grid>
        ))}
    </>
  );
};

const Address = ({form, section, change}) => {
  const {
    component: {Address: {addressCountry} = {}}
  } = useGlobal();
  const {t} = useTranslation();
  const selector = formValueSelector(form);
  const {
    data: {states}
  } = useMetadata();
  const studyAtHomeAddress = useSelector((state) => selector(state, section + ".studyAtHomeAddress"));

  const defaultProps =
    addressCountry === "AU"
      ? {
          renderers: {
            buildingPropertyName: SimpleField,
            flatUnitDetails: SimpleField,
            streetOrLotNumber: SimpleField,
            streetAddress: SimpleField,
            suburb: SimpleField,
            city: SimpleField,
            country: (props) => <Country fullWidth {...props} />,
            state: State,
            postcode: (props) => (
            <Optional name="Address.postcode">
              <SimpleField required validate={format({with: /\d{4}/, message: t("Section.Address.error.postcode")})} {...props} />
            </Optional>
                
            )
          },
          preferCountry: "AU",
          normalizers: {state: (value) => fromLabel(states, value, value)}
        }
      : {
          renderers: {
            streetAddress: SimpleField,
            suburb: SimpleField,
            city: SimpleField,
            country: (props) => <Country fullWidth {...props} />,
            state: SimpleField,
            postcode: SimpleField
          },
          preferCountry: "NZ"
        };

  return (
    <BaseSection section={section} title={t("Section.Address.sectionLabel")}>
      <AddressFields
        prefix="home"
        required
        title={t("Section.Address.homeAddress.label")}
        section={section}
        change={change}
        {...defaultProps}
      />
      <Grid item xs={12}>
        <YesNo label={t("Section.Address.studyAtHomeAddress.label")} name="studyAtHomeAddress" required />
      </Grid>
      {studyAtHomeAddress === "no" && (
        <AddressFields
          prefix="study"
          title={t("Section.Address.studyAddress.label")}
          section={section}
          change={change}
          {...defaultProps}
        />
      )}
    </BaseSection>
  );
};

export default Address;

Address.propTypes = {
  form: PropType.string.isRequired,
  section: PropType.string.isRequired
};

export function mapToApplication({home, studyAtHomeAddress, study}, {metadata}) {
  const permanentAddress = toAddress(merge({suburb: home.city}, home), metadata),
    studyAddress = isYes(studyAtHomeAddress)
      ? {isSameAsPermanent: true, ...permanentAddress}
      : {isSameAsPermanent: false, ...toAddress(merge({suburb: study.city}, study), metadata)};

  return {
    student: {
      permanentAddress,
      studyAddress
    }
  };
}

export function mapFromApplication(
  {student: {permanentAddress, studyAddress, studyAddress: {isSameAsPermanent} = {}} = {}} = {},
  {metadata}
) {
  return {
    home: fromAddress(permanentAddress, metadata),
    study: fromAddress(studyAddress, metadata),
    studyAtHomeAddress: toYesNo(isSameAsPermanent)
  };
}
