import {Alert} from "@material-ui/lab";
import React from "react";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import ErrorRedirect from "../error/ErrorRedirect";
import {ResumeEnrol} from "./ResumeEnrol";

/**
 * Resume after payment complete redirect from payment provider (e.g. windcave)
 * @param paymentSessionId param contains an id created for completion
 */
const ResumeAferPaymentComplete = ({...other}) => {
  const {sessionId: firstPaymentTransactionId} = useParams(),
    {sessionId} = useParams(), // not to be confused with our one
    {t} = useTranslation();
  return (
    <>
      {firstPaymentTransactionId ? (
        <ResumeEnrol
          sessionId={sessionId}
          resumeStep="acceptPayment"
          applicationUpdate={{
            payment: {
              firstPaymentTransactionId
            }
          }}
          {...other}
        >
          <Alert severity="success">{t("ResumeEnrol.paymentComplete.text")}</Alert>
        </ResumeEnrol>
      ) : (
        <ErrorRedirect i18nKey="ResumeEnrol.paymentComplete.noCompletionId" />
      )}
    </>
  );
};

export default ResumeAferPaymentComplete;
