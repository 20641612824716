/**
 * Verification required warning page
 */
import React from "react";
import {Typography, withStyles, Box, Paper, Grid} from "@material-ui/core";
import {Alert, AlertTitle} from "@material-ui/lab";
import {useTranslation, Trans} from "react-i18next";
import {useSelector} from "react-redux";
import VerifiedUserTwoToneIcon from "@material-ui/icons/VerifiedUserTwoTone";

const Verification = withStyles(
  (theme) => ({
    paragraph: {},
    request: {
      fontSize: "large",
      fontWeight: "600"
    },
    icon: {
      color: theme.palette.secondary.main,
      fontSize: "4rem",
      width: "auto",
      height: "auto"
    }
  }),
  {name: "UpVerification"}
)(({classes}) => {
  const {t} = useTranslation();
  const {firstName, lastName, emailAddress} = useSelector(({application: {data: {student} = {}} = {}}) => student || {});

  return (
    <>
      <Box paddingTop={5} marginBottom={5}>
        <Paper elevation={1}>
          <Box padding={3} marginBottom={3}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={2}>
                <VerifiedUserTwoToneIcon className={classes.icon} />
              </Grid>
              <Grid item xs={10}>
                <Typography variant="h3" gutterBottom>
                  <Trans
                    i18nKey={`Verification.request.${firstName && lastName && emailAddress ? "title" : "titleAnonymous"}`}
                    values={{
                      firstName,
                      emailAddress,
                      lastName
                    }}
                  />
                </Typography>
                <Typography variant="body1" gutterBottom color="primary" className={classes.request} component="div">
                  <Trans i18nKey={`Verification.request.text`} />
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Paper>
        <Alert severity="info">
          <AlertTitle>{t("Verification.needHelp.title")}</AlertTitle>
          <Typography variant="body1" gutterBottom className={classes.paragraph} component="div">
            <Trans i18nKey="Verification.needHelp.text" />
          </Typography>
        </Alert>
      </Box>
    </>
  );
});

export default Verification;
