import {Box, Grid, Link, Paper, withStyles} from "@material-ui/core";
import {Alert} from "@material-ui/lab";
import PhoneCallback from "@material-ui/icons/PhoneCallback";
import ThumbUpAltRounded from "@material-ui/icons/ThumbUpAltRounded";
import clsx from "clsx";
import i18next from "i18next";
import React from "react";
import {useEffect} from "react";
import {Trans, useTranslation} from "react-i18next";
import {useParams} from "react-router";
import {page} from "up-analytics";
import {Typography} from "up-form";

const styles = (theme) => ({
  icon: {
    fontSize: "5rem",
    verticalAlign: "middle"
  },
  noAction: {
    color: theme.palette.secondary.main
  },
  actionNeeded: {
    color: theme.palette.secondary.main
  }
});

const ContentPage = ({prefix, classes}) => {
  const {t} = useTranslation();
  const {contentKey} = useParams();
  useEffect(() => {
    page(prefix || "ContentPage", contentKey || prefix || "content");
  }, [contentKey, prefix]);
  return (
    <Grid container justifyContent="center" alignItems="center" style={{minHeight: "80vh"}}>
      <Paper variant="outlined">
        <Box padding={3} style={{textAlign: "center"}}>
          {i18next.exists(`${prefix}.${contentKey}.title`) && (
            <Typography variant="h3">
              <Trans
                i18nKey={t(`${prefix}.${contentKey}.title`)}
                components={{
                  noActionNeeded: <ThumbUpAltRounded className={clsx(classes.icon, classes.noAction)} />,
                  contactNeeded: <PhoneCallback className={clsx(classes.icon, classes.actionNeeded)} />,
                  externalLink: <Link />
                }}
              />
            </Typography>
          )}
          {i18next.exists(`${prefix}.${contentKey}.body`) && (
            <Typography variant="body1">
              <Trans i18nKey={`${prefix}.${contentKey}.body`} />
            </Typography>
          )}
        </Box>
        {["info", "warning", "error", "success"]
          .filter((severity) => i18next.exists(`${prefix}.${contentKey}.${severity}`))
          .map((severity, i) => (
            <Alert variant="outlined" key={i} severity={severity} style={{margin: "1em"}}>
              <Trans i18nKey={`${prefix}.${contentKey}.${severity}`} />
            </Alert>
          ))}
      </Paper>
    </Grid>
  );
};

export default withStyles(styles)(ContentPage);
