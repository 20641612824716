import {Box, Paper} from "@material-ui/core";
import React from "react";
import {useTranslation} from "react-i18next";
import {Route, Switch, useLocation, useRouteMatch} from "react-router-dom";
import ErrorRedirect from "../error/ErrorRedirect";
import {Typography} from "up-form";

const PaymentResult = () => {
  const query = new URLSearchParams(useLocation().search),
    sessionId = query.get("sessionId"),
    {t} = useTranslation(),
    {path} = useRouteMatch();

  return (
    <Paper>
      <Box padding="10em" margin="auto">
        <Switch>
          <Route exact path={`${path}/approved`}>
            <Typography variant="subtitle2">{t("PaymentResult.approved.text", {sessionId})}</Typography>
          </Route>
          <Route exact path={`${path}/declined`}>
            <Typography variant="subtitle2">{t("PaymentResult.declined.text", {sessionId})}</Typography>
          </Route>
          <Route exact path={`${path}/cancelled`}>
            <Typography variant="subtitle2">{t("PaymentResult.cancelled.text", {sessionId})}</Typography>
          </Route>
          <Route>
            <ErrorRedirect status="404" />
          </Route>
        </Switch>
      </Box>
    </Paper>
  );
};

export default PaymentResult;
