import {Grid} from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import {useTranslation} from "react-i18next";
import {BaseSection, fromId, StudyReason, toId, Typography} from "up-form";
const Reason = ({section, form}) => {
  const {t} = useTranslation();
  const prefix = "Section.Reason";

  return (
    <BaseSection section={section} title={t(`${prefix}.title`)}>
      <Grid item xs={12}>
        <Typography paragraph variant="body1">
          {t(`${prefix}.studyReason.intro`)}
        </Typography>
        <StudyReason label={t(`${prefix}.studyReason.label`)} name="studyReason" required />
      </Grid>
    </BaseSection>
  );
};

Reason.propTypes = {
  form: PropTypes.string.isRequired,
  section: PropTypes.string.isRequired
};

export default Reason;

export function mapToApplication({studyReason}) {
  return {
    education: {
      studyReasonId: toId(studyReason)
    }
  };
}

export function mapFromApplication({education: {studyReasonId} = {}}, {metadata: {studyReasons}}) {
  return {
    studyReason: fromId(studyReasons, studyReasonId)
  };
}
