/**
 * Form for Payment
 *
 * 1 Create an order/invoice if none exists and payment not yet made
 * 2 Show link to payment page (disabled if not needed)
 */
import {Button, Card, CardContent, Grid} from "@material-ui/core";
import {loadStripe} from "@stripe/stripe-js";
import PropType from "prop-types";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {formValueSelector} from "redux-form";
import {BaseSection, Currency, Message, useGlobal, useSkeleton, toId, fromLabel} from "up-form";
import {createPaymentSession, createZipCheckoutSession, useMetadata} from "up-state";
import {ReactComponent as Stripe} from "../../icons/stripe.svg";
import {ReactComponent as Zip} from "../../icons/zip.svg";
import {Typography} from "up-form";
import {useLocation} from "react-router-dom";
import {Alert, AlertTitle} from "@material-ui/lab";


const AcceptPayment = ({form, section, change}) => {
  const pendingPreviousUpdates = useSkeleton();
  const {t} = useTranslation(),
    location = useLocation(),
    queryParams = new URLSearchParams(location.search),
    result = queryParams.get('result'),
    resultApproved = result?.localeCompare("approved", undefined, { sensitivity: 'base' }) === 0,
    resultCancelled = result?.localeCompare("cancelled", undefined, { sensitivity: 'base' }) === 0,
    resultDeclined = result?.localeCompare("declined", undefined, { sensitivity: 'base' }) === 0,
    prefix = "Section.AcceptPayment",
    {data: {invoiceNumber} = {}, pending: orderPending, error: orderError} = useSelector((state) => state.createOrder || {}),
    {
      data: {id: paymentSessionId, client_reference_id, amount_total, currency: stripe_currency, publishable_key} = {},
      pending: paymentSessionPending,
      error: paymentSessionError
    } = useSelector((state) => state.createPaymentSession || {}),
    {
      data: {id: zipCheckoutId, uri: zipCheckoutUri, order: { reference: zip_reference, amount: zip_amount, currency: zip_currency} = {}} = {},
      pending: zipCheckoutPending,
      error: zipCheckoutError
    } = useSelector((state) => state.createZipCheckoutSession || {}),
    { 
      pending: applicationPending,
      data: {
        opportunity: {opportunityCRMId: opportunityId, sessionId: applicationSessionId} = {},
        payment: {firstPaymentTransactionId, invoiceId: preExistingInvoiceId, paymentProviderId} = {}
      } = {}
    } = useSelector((state) => state.application || {}),
    {data: {paymentProviders} = {}} = useMetadata(),
    [checkoutError, setCheckoutError] = useState(),
    [redirectPending, setRedirectPending] = useState(false),
    {
      component: {isZipEnabled},
      component: {storeCardDetails},
      links: {paymentApproved: approved, paymentDeclined: declined, paymentCancelled: cancelled, zipRedirectUrl},
      stripe: {mockPayment}
    } = useGlobal(),
    selector = formValueSelector(form),
    isPaymentPlan = useSelector((state) => /Plan/.test((selector(state, "checkout.method") || {}).label)),
    isZipDirectPayment = toId(fromLabel(paymentProviders, "Zip AU")) === paymentProviderId,
    dispatch = useDispatch(),
    anyError = checkoutError || orderError || paymentSessionError || zipCheckoutError,
    anyPending = pendingPreviousUpdates || applicationPending ||paymentSessionPending || zipCheckoutPending || orderPending || redirectPending,
    canProceed = !anyError && (paymentSessionId || zipCheckoutId),
    invoiceId = invoiceNumber || preExistingInvoiceId,
    reference = client_reference_id || zip_reference,
    currency = stripe_currency || zip_currency;

  useEffect(() => {
    if (opportunityId && invoiceId && !(paymentSessionId || zipCheckoutId || anyPending || anyError || firstPaymentTransactionId)) {
      if (isZipDirectPayment) {
        dispatch(
          createZipCheckoutSession(opportunityId, 
            {
              redirectUri: `${zipRedirectUrl}/${applicationSessionId}`
            }
          ));
      } else {
        dispatch(
          createPaymentSession(opportunityId, {
            zipEnabled: isZipEnabled,
            setupFutureUsage: storeCardDetails,
            callbackUrls: {
              approved: `${approved}/${applicationSessionId}`,
              declined: `${declined}/${applicationSessionId}`,
              cancelled: `${cancelled}/${applicationSessionId}`
            }
          })
        );
      }
    }
  }, [
    approved,
    applicationSessionId,
    anyError,
    anyPending,
    declined,
    zipRedirectUrl,
    dispatch,
    cancelled,
    firstPaymentTransactionId,
    invoiceId,
    isPaymentPlan,
    isZipDirectPayment,
    isZipEnabled,
    opportunityId,
    paymentSessionId,
    storeCardDetails,
    zipCheckoutId
  ]);

  const actionKey = `${prefix}.${isZipDirectPayment ? "zip" : isPaymentPlan ? "deposit" : "full"}`;
  return (
    <BaseSection section={section} title={t(`${prefix}.title`)}>
      {canProceed && (
        <>
          <Grid item xs={12}>
            <Card variant="outlined">
              <CardContent>
                <Grid container justifyContent="flex-end">
                  {isZipDirectPayment ? <Zip style={{height: "4rem"}} /> : <Stripe style={{height: "5rem"}} />}
                </Grid>
                <Typography variant="h2">{t(`${actionKey}.title`)}</Typography>
                <Currency
                  variant="h3"
                  value={amount_total / 100 || zip_amount}
                  signDisplay="auto"
                  currency={(currency || "AUD").toUpperCase()}
                  currencyDisplay="code"
                />
                <Typography variant="h2">{t(`${prefix}.reference`)}</Typography>
                <Typography variant="h3">{reference}</Typography>
                <Typography variant="body1" paragraph>
                  {t(`${actionKey}.text`)}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          { result && !resultApproved && !resultCancelled && (
            <Grid item xs={12}>
              <Alert severity={ resultDeclined ? "error" : "warning"}>
                <AlertTitle>{
                              resultDeclined ? t("ResumeEnrol.zipCheckout.declined.title") 
                              : t("ResumeEnrol.zipCheckout.referred.title")
                            }
                </AlertTitle>
                  {
                    resultDeclined ? t("ResumeEnrol.zipCheckout.declined.message") 
                    : t("ResumeEnrol.zipCheckout.referred.message")
                  }
              </Alert>
            </Grid>
          )}
          <Grid item container xs={12} spacing={2} justifyContent="flex-end">
            <Button
              data-payment-session-id={paymentSessionId}
              color="primary"
              variant="contained"
              data-name="AcceptPayment.proceed"
              onClick={async () => {
                if (!mockPayment) {
                  if (isZipDirectPayment) {
                    setRedirectPending(true);
                    document.location.replace(zipCheckoutUri);
                    setRedirectPending(false);
                  } else {
                    // When the customer clicks on the button, redirect them to Checkout.
                    setRedirectPending(true);
                    const stripe = await loadStripe(publishable_key);
                    const result = await stripe.redirectToCheckout({
                      sessionId: paymentSessionId
                    });
                    const {error} = result;
                    setRedirectPending(false);
                    error && setCheckoutError({message: error});
                  }
                } else {
                  document.location.replace(`${approved}/${applicationSessionId}`);
                }
              }}
            >
              {t(`${prefix}.proceed.label`)}
            </Button>
          </Grid>
        </>
      )}
      {firstPaymentTransactionId && (
        <Typography variant="body1" gutterBottom>
          {t(`${prefix}.approved`)}
        </Typography>
      )}
      {anyError && <Message variant="error" open message={anyError.message} />}
      <Message open={anyPending} variant="busy" message={t(`${prefix}.busy`)} />
    </BaseSection>
  );
};

export default AcceptPayment;

AcceptPayment.propTypes = {
  form: PropType.string.isRequired,
  section: PropType.string.isRequired
};

/**
 * Note we never map to/from application data here - application update action is performed on ResumeAfterPaymentComplete
 */
