import React, { useEffect, useState } from 'react';
import { Button, Dialog, IconButton, withStyles } from '@material-ui/core';
import {useGlobal} from "up-form";
import CloseIcon from "@material-ui/icons/Close";

const ZipLandingPagePopup = withStyles((theme) => {
    return {
        popupLink: {
            color: "black",
            textTransform: 'none',
            textDecoration: 'underline',
            padding: 0,
            '&:hover': {
                backgroundColor: 'transparent',
                textDecoration: 'underline'
            }
        }
    }
})(({classes}) => {
    const {isDev, zip: {tenantKey} = {}} = useGlobal();
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        if (open) {
            const script = document.createElement('script');
            script.src = 'https://static.zip.co/lib/js/zm-widget-js/dist/zip-widget.min.js';
            script.async = true;

            document.body.appendChild(script);

            return () => {
                document.body.removeChild(script);
            }
        }

    }, [open]);

    return (
        <>
            <Button disableRipple className={classes.popupLink} onClick={handleClickOpen}>Learn more</Button>
            <Dialog fullWidth open={open} onClose={handleClose}>
                <div style={{position: 'sticky', top: 0, zIndex: 1}}>
                    <div style={{position: 'absolute', right: 0}}>
                        <IconButton
                            aria-label="close"
                            onClick={handleClose}
                        >
                            <CloseIcon />
                        </IconButton>
                    </div>
                </div>
                <div zm-asset="landingpage" 
                    data-env= {isDev ? "sandbox" : "production"}
                    data-zm-merchant= {tenantKey}
                    zm-widget="inline"
                    data-zm-region="au"
                    style={{paddingTop: 0}}
                    >
                </div>
            </Dialog>
        </>
    )
});

export default ZipLandingPagePopup;