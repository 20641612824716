import {Grid} from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {formValueSelector} from "redux-form";
import {BaseSection, DisabilityType, fromId, isYes, toId, toYesNo, YesNo, useGlobal} from "up-form";
const Disability = ({section, form}) => {
  const {t} = useTranslation();
  const selector = formValueSelector(form);
  const disability = useSelector((state) => selector(state, `${section}.disability`) || {});
  const prefix = "Section.Disability";
  const {
    component: {
      Disability: {excludedTypes} = {}
    }
  } = useGlobal();

  return (
    <BaseSection section={section} title={t(`${prefix}.title`)}>
      <Grid item xs={12}>
        <YesNo label={t(`${prefix}.disability.label`)} name="disability" required />
      </Grid>
      <Grid item xs={12}>
        {disability === "yes" && <DisabilityType name="disabilityTypes" label={t(`${prefix}.disabilityTypes.label`)} required filter={({label}) => !(excludedTypes || []).includes(label)}/>}
      </Grid>
    </BaseSection>
  );
};

Disability.propTypes = {
  form: PropTypes.string.isRequired,
  section: PropTypes.string.isRequired
};

export default Disability;

export function mapToApplication({disability, disabilityTypes}) {
  return {
    health: {
      disability: isYes(disability),
      disabilityTypeIds: [disabilityTypes || []].flat().map(toId)
    }
  };
}

export function mapFromApplication({health: {disability, disabilityTypeIds} = {}}, {metadata: {disabilityTypes}}) {
  return {
    disability: toYesNo(disability),
    disabilityTypes: (disabilityTypeIds || []).filter((v) => !!v).map((id) => fromId(disabilityTypes, id))
  };
}
