import {Grid, Grow, withStyles} from "@material-ui/core";
import {useTheme} from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {ToggleButton} from "@material-ui/lab";
import clsx from "clsx";
import {uniqBy} from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {formValueSelector} from "redux-form";
import {ChooseOne, ToggleButtonBag, Typography, useGlobal} from "up-form";

const ChooseIntake = withStyles(
  (theme) => ({
    rowIntake: {
      width: "100%",
      "& th": {
        width: "1%",
        whiteSpace: "nowrap",
        padding: "0 1em 0 0"
      }
    },
    columnIntake: {
      width: "100%",
      borderSpacing: "1em"
    },

    intakeButton: {minWidth: "10em", width: "100%", borderRadius: 0, borderWidth: "2px"}
  }),
  {name: "UpChooseIntake"}
)(({form, section, name, course, classes, required, change, clearFields}) => {
  const {t} = useTranslation();
  const {component: {ChooseIntake: {intakeOrientationThreshold = 6} = {}} = {}} = useGlobal();
  const prefix = `Section.ChooseIntake`;
  const theme = useTheme();
  const locations =
    course &&
    course.intakeList &&
    uniqBy(
      course.intakeList.filter(({location}) => !!location),
      ({location}) => location
    ).map(({location, locationCRMId}) => ({locationName: location, locationCRMId}));

  const selector = formValueSelector(form);
  const intake = useSelector((state) => selector(state, section + ".intake"));
  // Highlighted location is initiallly either that associated with existing intake or only location available that leaft unset
  const [location, setLocation] = useState(
    locations.find(({locationCRMId}) => (intake && locationCRMId === intake.locationCRMId) || locations.length === 1)
  );
  const {locationName, locationCRMId} = location || {};
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const locationsOrientation = isMobile || (locations && locations.length) > intakeOrientationThreshold ? "vertical" : "horizontal";
  const intakes =
    course &&
    course.intakeList &&
    [...new Set(course.intakeList.filter((intake) => intake.locationCRMId === locationCRMId && intake.courseStartDate))].map(
      (intake) => ({
        ...intake,
        startDate: moment(intake.courseStartDate)
      })
    ); // map date to something more useful
  const byYear = // Map of intakes by year
    intakes &&
    intakes
      .sort(
        // Sort in date order first so each byYear array will be sorted
        ({startDate: a}, {startDate: b}) => a.unix() - b.unix()
      )
      .reduce((byYear, intake) => {
        const year = intake.startDate.year();
        return {
          ...byYear,
          [year]: [...(byYear[year] || []), intake]
        };
      }, {});
  const fullest = byYear && Object.values(byYear).reduce((mx, intakes) => Math.max(mx, intakes.length), 0); // number of intakes in fullest year
  const intakesAsColumn = isMobile || fullest > intakeOrientationThreshold; // Display as columns if number of intakes in a year is high
  useEffect(() => {
    // check consistency of any current intak with available list
    if (Array.isArray(intakes)) {
      if (intakes.length === 1 && !(intake && intake.intakeCRMId === intakes[0].intakeCRMId))
        change(`${section}.intake`, intakes[0]);
      else if (intakes.length === 0 || (intake && !intakes.find(({intakeCRMId}) => intakeCRMId === intake.intakeCRMId))) {
        clearFields(false, false, `${section}.intake`);
      }
    }
  }, [intakes, change, clearFields, section, intake]);
  return (
    <Grid container direction="column">
      <Grid item xs={12}>
        {locations ? (
          <ChooseOne
            label={t(`${prefix}.location.label`)}
            required={required}
            orientation={locationsOrientation}
            disabled={locations.length === 1}
            options={locations.map((location) => ({label: location.locationName, value: location}))}
            onChange={(v) => {
              setLocation(v);
            }}
            value={location}
          />
        ) : (
          <Typography color="error">{t("ChooseIntake.location.error.noLocationsFound")}</Typography>
        )}
      </Grid>

      <Grow mountOnEnter unmountOnExit in={intakes && intakes.length > 0} timeout={1000}>
        <Grid item xs={12}>
          <ToggleButtonBag
            variant="outlined"
            fullWidth
            name={name}
            required={required}
            label={t(`${prefix}.${intakes.length === 1 ? "startDate.warning.singleDate" : "startDate.label"}`, {
              location: locationName
            })}
            renderComponent={({input: {onFocus, onBlur, onChange, value}, classes: extraClass}) => {
              const IntakeButton = ({intake}) => (
                <ToggleButton
                  className={clsx(classes.intakeButton, extraClass.button)}
                  disabled={intakes.length === 1}
                  selected={intake.intakeCRMId === value.intakeCRMId}
                  onChange={() => {
                    onChange(intake);
                  }}
                  value={intake}
                >
                  {intake.startDate.format("D MMMM YYYY")}
                </ToggleButton>
              );
              const Heading = ({year}) => (
                <Typography variant="h5" color="textSecondary">
                  {t(`${prefix}.startDate.year`, {
                    year
                  })}
                </Typography>
              );
              const years = Object.keys(byYear);
              return (
                <>
                  {intakesAsColumn ? ( // show as yearly columns
                    <table className={classes.columnIntake}>
                      {years.length > 1 && ( // no headings if single year
                        <thead>
                          <tr>
                            {years.map((year, i) => (
                              <th>
                                <Heading year={year} />
                              </th>
                            ))}
                          </tr>
                        </thead>
                      )}
                      <tbody>
                        {[...Array(fullest)].map((x, row) => (
                          <tr key={row}>
                            {years.map((year, column) => {
                              const intakes = byYear[year];
                              return <td key={column}>{row < intakes.length && <IntakeButton intake={intakes[row]} />}</td>;
                            })}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    // show as yearly rows
                    <table className={classes.rowIntake}>
                      <tbody>
                        {years.map((year, i) => (
                          <tr key={i}>
                            {years.length > 1 && ( // no headings if single year
                              <th>
                                <Heading year={year} />
                              </th>
                            )}
                            {byYear[year].map((intake, i) => (
                              <td key={i}>
                                <IntakeButton intake={intake} />
                              </td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                </>
              );
            }}
          />
        </Grid>
      </Grow>
    </Grid>
  );
});

ChooseIntake.propTypes = {
  course: PropTypes.object.isRequired,
  form: PropTypes.string.isRequired,
  locationName: PropTypes.string,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  section: PropTypes.string.isRequired,
  studyModeName: PropTypes.string
};

export default ChooseIntake;
