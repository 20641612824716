import {Grid, Paper, Step, StepContent, StepLabel, withStyles} from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {formValueSelector} from "redux-form";
import {acceptance} from "redux-form-validators";
import {BaseSection, Checkbox, Typography} from "up-form";
import {useApplication, useCourses, useMetadata} from "up-state";
import config from "../../config";
import Agreement from "../content/Agreement";
import Agreement_IAH from "../content/Agreement_IAH";
import Agreement_VET from "../content/Agreement_VET";
import Agreement_Colab from "../content/Agreement_Colab";
import {mapFromApplication as mapCourse} from "./ChooseCourse";

const {
  global: {
    component: {
      TermsAndConditions: {requiredPageConfirmations = []}
    }
  }
} = config;

const TermsAndConditions = withStyles((theme) => {
  return {
    stepConfirmed: {
      opacity: ".5"
    },
    confirmationRoot: {
      borderColor: theme.palette.secondary.light
    }
  };
})(({section, form, classes, change}) => {
  const {t} = useTranslation();
  const prefix = "Section.TermsAndConditions";
  const selector = formValueSelector(form);
  const confirmed = useSelector(
    (state) => selector(state, `${section}.confirmed`) || {}
  );

  const {data: metadata, pending: pendingMetadata} = useMetadata();
  const {data: courses, pending: coursesPending} = useCourses();
  const {data: application, pending: applicationPending} = useApplication();
  const {course: {isHEOrVETFunded, providerName} = {}} =
    (courses &&
      application &&
      metadata &&
      mapCourse(application, {courses, metadata})) ||
    {};
  const isIAH = providerName === "IAH";
  const isColab = providerName === "Colab";

  const pending = coursesPending || applicationPending || pendingMetadata;
  console.log(pending);

  const active = requiredPageConfirmations.findIndex(
    (name) => !confirmed[name]
  );
  // Waiting for anything
  const AgreementToShow = isIAH
    ? Agreement_IAH
    : isColab 
    ? Agreement_Colab
    : isHEOrVETFunded
    ? Agreement_VET
    : Agreement;

  return (
    <BaseSection
      section={section}
      title={t("Section.TermsAndConditions.title")}
    >
      <Grid item>
        <AgreementToShow
          orientation="vertical"
          renderPage={(index, children) => {
            const name = requiredPageConfirmations[index];
            const isConfirmed = !!confirmed[name];
            return (
              <Step
                key={index}
                active={index === active}
                expanded
                completed={isConfirmed}
              >
                <StepLabel>
                  <Typography variant="subtitle1">
                    {t(`${prefix}.page.${name}.step.label`)}
                  </Typography>
                </StepLabel>
                <StepContent orientation="vertical">
                  <div className={isConfirmed ? classes.stepConfirmed : ""}>
                    {children}
                  </div>
                  <Paper
                    className={!isConfirmed ? classes.confirmationRoot : ""}
                    variant="outlined"
                  >
                    <Grid container justifyContent="center">
                      <Checkbox
                        required
                        validate={acceptance()}
                        color="secondary"
                        name={`confirmed.${name}`}
                        label={t(`${prefix}.page.${name}.confirm.label`)}
                      />
                    </Grid>
                  </Paper>
                </StepContent>
              </Step>
            );
          }}
        />
      </Grid>
    </BaseSection>
  );
});

TermsAndConditions.propTypes = {
  onChange: PropTypes.func
};

export default TermsAndConditions;

export function mapToApplication({confirmed}) {
  return {
    declaration: {
      agreeTerms: requiredPageConfirmations.every((page) => confirmed[page])
    }
  };
}

export function mapFromApplication({declaration: {agreeTerms} = {}}) {
  return {
    confirmed: requiredPageConfirmations.reduce(
      (acc, page) => ({[page]: agreeTerms, ...acc}),
      {}
    )
  };
}
