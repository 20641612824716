/**
 * Selection of course
 */
import {Grid} from "@material-ui/core";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {formValueSelector} from "redux-form";
import {BaseSection, Course, fromId, fromLabel, StudyMode, toCourseIntake, toId, useGlobal} from "up-form";
import ChooseIntake from "./ChooseIntake";
import {

  SkeletonProvider
 
} from "up-form";

const ChooseCourse = ({section, displayOnly = false, form, change, clearFields}) => {
  const {t} = useTranslation();
  const {provider} = useGlobal();
  const selector = formValueSelector(form);
  const course = useSelector((state) => selector(state, section + ".course"));
  const {marketingName, name, isHEOrVETFunded} = course || {};
  const prefix = "Section.ChooseCourse";
  const courseTitle = marketingName || name;
  const {data: createLeadResponse} = useSelector((state) => state.createLead || {});
  return (
    <SkeletonProvider busy={!createLeadResponse}>
    <BaseSection section={section} title={displayOnly ? t(`${prefix}.courseTitle`, {name: courseTitle}) : t(`${prefix}.title`)}>
    <Grid item xs={12}>
        {!displayOnly && (
          <Course
            name="course"
            label={t(`${prefix}.course.label`)}
            required
            provider={provider}
            transform={(courses) => courses.filter(({intakeList}) => Array.isArray(intakeList) && intakeList.length > 0)}
          />
        )}
      </Grid>
      {isHEOrVETFunded && course && (
        <>
          <Grid item xs={12}>
          <StudyMode label={t(`${prefix}.studyMode.label`)} required name={"studyMode"} />
          </Grid>
          <Grid item xs={12}>
            <ChooseIntake
              form={form}
              section={section}
              required
              course={course}
              name="intake"
              change={change}
              clearFields={clearFields}
            />
          </Grid>
        </>
      )}
    </BaseSection>
    </SkeletonProvider>
  );
};

export function mapToLead({course: {productId} = {}}) {
  return {
    productId
  };
}

export function mapToApplication(
  {
    course: {
      isHEOrVETFunded,
      intakeList: [{intakeCRMId: firstIntakeCRMId}]
    },
    intake: {intakeCRMId} = {},
    studyMode
  },
  {metadata: {paymentProviders}}
) {
  return {
    opportunity: {
      intakeCRMId: isHEOrVETFunded && intakeCRMId ? intakeCRMId : firstIntakeCRMId,
      studyModeId: toId(studyMode)
    },
    payment: isHEOrVETFunded
      ? {
          paymentProviderId: toId(fromLabel(paymentProviders, "VSL"))
        }
      : undefined
  };
}
export function mapFromApplication({opportunity: {intakeCRMId, studyModeId} = {}}, {courses, metadata: {studyModes}}) {
  return {
    ...(intakeCRMId ? toCourseIntake({intakeCRMId}, courses) : {}),
    studyMode: fromId(studyModes, studyModeId)
  };
}

ChooseCourse.propTypes = {
  form: PropTypes.string.isRequired,
  section: PropTypes.string.isRequired
};

export default ChooseCourse;
